import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Row } from 'react-bootstrap';
import InputsService from '../../../services/inputs';
import "../index.scss";
import Convert from '../../../utils/convert';
import OpenCurrent from '../components/open_current';
import OpenElement from '../components/open_element';
import ViewIcon from '../../../components/icons/view';
import StartIcon from '../../../components/icons/start';
import BackIcon from '../../../components/icons/back';
import EventOpenIcon from '../../../components/icons/event_open';


const ListElements = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataElements, setDataElements] = useState('');
  const [dataProductionElements, setDataProductionElements] = useState('');
  const [vElements, setvElements] = useState(false);

  const id = useParams();

  const handleElementCreated = () => {
    setvElements(prev => !prev);
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };
    const fetchFlowchartElements = async () => {
      const user_data = JSON.parse(localStorage.getItem('user_data'));
      if (user_data) {
        try {
          const flowchartsElements = await InputsService.getElementsFlowchart(id.id);
          if (flowchartsElements.status === 200) {
            setDataElements(flowchartsElements.data.data)
            localStorage.setItem('elementsFlowData', JSON.stringify(flowchartsElements.data.data.elements));
          }
        } catch (error) {
        }
      }
    };

    const fetchFlowchartProductionElements = async () => {
      const user_data = JSON.parse(localStorage.getItem('user_data'));
      if (user_data) {
        try {
          const flowchartsElements = await InputsService.getElementsProdutionFlowchart(id.id);
          if (flowchartsElements.status === 200) {
              setDataProductionElements(flowchartsElements.data.data)
          }
        } catch (error) {
        }
      }
    };
    
    fetchRole();
    fetchFlowchartElements();
    fetchFlowchartProductionElements();
  }, [navigate, id, vElements]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser === "" || !roleUser.trim()) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  const limitWords = (text, wordLimit) => {
    if (!text) {
      return 'Descrição indisponível';  // Texto padrão caso a descrição seja nula ou indefinida
    }
    
  const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const handleOpenProcess = async (flowchartElement) => {
    try {
      const flowchartsElements = await InputsService.openElementProcess(id.id, flowchartElement.element_id, []);
      if (flowchartsElements.status === 200) {
          setDataProductionElements(flowchartsElements.data.data)
          handleElementCreated();
      }
    } catch (error) {
    }
  }
  
  
  const isEventOpenById = (id) => {
    if (Array.isArray(dataProductionElements)) {
      // Procura pelo objeto cujo event_id ou element_id é igual ao id fornecido
      const event = dataProductionElements.find(e => e.element_id === id);
    
      // Verifica se o objeto foi encontrado e se o is_open é true
      return event ? event : false;
    }
  }
  
  const typeOrder = ['INPUT', 'PROCESS', 'OUTPUT'];

  const viewElement = (element_id, event_id) => { 
    const elementData = {
      element_id: element_id,
      event_id: event_id.event_id,
      flowchart_id: id.id
    };

    localStorage.setItem('elementData', JSON.stringify(elementData));

    navigate(`/gestaoformularios/processo/elemento/${id.id}`);
  };

  const handleBackPage = () => {
    navigate(-1);
  };

  const Back = ({ onClick }) => (
    <button onClick={onClick} style={{ background: 'none', border: 'none' }}>
      <BackIcon />
    </button>
  );

  return (
    <Container className='container-custom-fluid'>
      <Row>
      <header className='header header_operator'>
        <div>
              {/* <Breadcrumbs />  */}
              <Back onClick={handleBackPage} />
              <h1>{dataElements.name}</h1>
        </div>
      </header>
        <section>
          <h2>Processos</h2>
        </section>
        <section className='container_card_element'>
          {dataElements?.elements && dataElements.elements.length > 0 ? (
            Object.keys(
              dataElements.elements
                .sort((a, b) => typeOrder.indexOf(a.element_type) - typeOrder.indexOf(b.element_type)) // Ordena pelo tipo
                .reduce((acc, element) => {
                  const type = element.element_type;
                  if (!acc[type]) {
                    acc[type] = [];
                  }
                  acc[type].push(element);
                  return acc;
                }, {})
            ).map((elementType, index) => (
              <div key={index}>
                <h3>{Convert.convertTypeElementBR(elementType)}</h3> {/* Exibe o tipo de elemento */}
                <div className='container_cards_elements'>
                  {dataElements.elements
                    .filter(element => element.element_type === elementType && element.active === true)
                    .map((flowchartElement, idx) => (
                      <div key={idx} className={isEventOpenById(flowchartElement.element_id) ? 'card_flowchart card_open' : 'card_flowchart'}>
                        <div className="card_title">
                          <div className='container_card_open'>
                              <h2 className="flowchart-name">{flowchartElement.name}</h2> 
                              {isEventOpenById(flowchartElement.element_id) ? (
                                <span><EventOpenIcon /> Aberto</span>
                              ) : null}
                          </div>
                          <span className="flowchart-description">
                            {limitWords(flowchartElement.description, 10)}
                          </span>
                        </div>
                        <div className="card-action">
                            {flowchartElement.element_type === "INPUT" && !isEventOpenById(flowchartElement.element_id) ? (
                              <>
                                <div>
                                  <Button variant="primary" size="md" onClick={() => handleOpenProcess(flowchartElement)}><StartIcon /> Iniciar processo</Button>
                                </div>
                              </>
                            ) : isEventOpenById(flowchartElement.element_id) ? (
                              <>
                                <div>
                                  <Button variant="primary" size="md" onClick={()=>viewElement(flowchartElement.element_id, isEventOpenById(flowchartElement.element_id))}><ViewIcon /> Ver eventos abertos</Button>
                                  <OpenCurrent element={flowchartElement} event={isEventOpenById(flowchartElement.element_id)} handleElementCreated={handleElementCreated}/>
                                </div>
                              </>
                            ) : !isEventOpenById(flowchartElement.element_id) ? (
                              <>
                                <div>
                                 <OpenElement element={flowchartElement} event={isEventOpenById(flowchartElement.element_id)} handleElementCreated={handleElementCreated} /> 
                                </div>
                              </>
                            ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
              </div>
            ))
          ) : (
            <p>Nenhum processo disponível.</p>
          )}
        </section>
      </Row>
    </Container>
  );
};

export default ListElements;