import React, {useState } from 'react';
import { Modal, Button, ListGroup} from 'react-bootstrap';

const PropertiesAlert = ({checkFrequency}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" size="sm" onClick={handleShow}>
        {checkFrequency.length}
      </Button>

      <Modal show={show} onHide={handleClose} size='lg' scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Propriedades em atrasos</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
        <div className='container_descripiton'>
            <ListGroup>
                {checkFrequency.map((result, index) => (
                    <ListGroup.Item key={index}>
                        <strong>{result.name}</strong> - {result.message}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PropertiesAlert;