import './index.scss';
import "../../style/variables.scss";
import Spinner from '../icons/spinner';

const Loading = () => {
    return (
        <div className="loading-overlay">
            <div className="spinner">
                <Spinner />
            </div>
            <p>Carregando...</p>
        </div>
    );
};

export default Loading;
