import "./index.scss";

const HierarchyIcon = () => {
    return (
        <div className="icon hierarchy-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-hierarchy">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M6.5 17.5l5.5 -4.5l5.5 4.5" />
                <path d="M12 7l0 6" />
            </svg>
        </div>
    );
};

export default HierarchyIcon;