import React, { useEffect, useState } from 'react';
import AuthService from '../../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/breadcrumbs';
import { Button, Container, Row } from 'react-bootstrap';
import InputsService from '../../services/inputs';
import "./index.scss";
import HierarchyIcon from '../../components/icons/hierarchy';
import ViewIcon from '../../components/icons/view';

const ManageForm = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataFlowcharts, setDataFlowcharts] = useState('');


  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    const fetchFlowchartUser = async () => {
      const user_data = JSON.parse(localStorage.getItem('user_data'));
      if (user_data) {
        try {
          const flowchartsUser = await InputsService.getFlowchartPermissionsUser(user_data.user_id);
          if (flowchartsUser.status === 200) {
            setDataFlowcharts(flowchartsUser.data.data);
          }
        } catch (error) {
        }
      }
    };
    
    fetchRole();
    fetchFlowchartUser();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("OPERATOR")) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  const limitWords = (text, wordLimit) => {
    if (!text) {
      return 'Descrição indisponível';  // Texto padrão caso a descrição seja nula ou indefinida
    }
    
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  return (
    <Container className='container-custom-fluid'>
      <Row>
      <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Elementos</h1>
          </div>
        </header>
        <section>
          <h2>Processos</h2>
        </section>
        <section className='container_card_flowchart'>
          {dataFlowcharts?.flowcharts && dataFlowcharts.flowcharts.length > 0 ? (
            dataFlowcharts.flowcharts.filter(({ flowchart }) => flowchart.active).map((flowchartData, index) => (
              <div key={index} className="card_flowchart">
                <div className='card_title'>
                  <h2 className="flowchart-name">{flowchartData.flowchart.name}</h2>
                  <span className="flowchart-description">
                    {limitWords(flowchartData.flowchart.description, 10)}
                  </span>
                </div>
                <div className='card_icon'>
                  <HierarchyIcon />
                </div>
                <div className='card-action'>
                  <Link to={`/gestaoformularios/processo/${flowchartData.flowchart.flowchart_id}`}><Button variant='primary' size='md'><ViewIcon /> Ver elementos</Button></Link>
                </div>
              </div>
            ))
          ) : (
            <p>Nenhum processo disponível.</p>
          )}
        </section>
      </Row>
    </Container>
  );
};

export default ManageForm;