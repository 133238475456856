import React, { useEffect, useState } from 'react';
import AuthService from '../../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Container, Row } from 'react-bootstrap';
import Breadcrumbs from '../../components/breadcrumbs';
import FrequencyService from '../../services/frequency';
import EditIcon from '../../components/icons/edit';
import DataTable from 'react-data-table-component';
import CustomInput from '../../components/input';
import NewFrequency from './component/new_frequency';

const FrequencyList = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataFrequencys, setDataFrequencys] = useState([]);
  const [vFrequencys, setvFrequencys] = useState(false);
  const [filterText, setFilterText] = useState('');

  const filteredItems = dataFrequencys.filter(item => 
    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleFrequencyCreated = () => {
    setvFrequencys(prev => !prev);
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("MASTER")) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  useEffect(() => {
    const fetchLocations = async () => {
      const location = await FrequencyService.getFrequencys();
      if (location) {
        setDataFrequencys(location.data.data)
      } else {
        console.log('Não foi possível obter os usuários.');
      }
    };
  
    fetchLocations();
  }, [vFrequencys]);

  const EditFrequency = ({ id }) => (
    <Link to={`/frequencias/${id}`}>
      <EditIcon />
    </Link>
  );
  
  const ActiveFrequency = ({ active }) => (
    <Badge bg={active ? "success" : "danger"}>{active ? "Ativo" : "Desativado"}</Badge>
  );

  const columns = [
    {
      name: 'ID',
      selector: row => row.frequency_id,
      sortable: true,
    },
    {
      name: 'Nome',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Minutos',
      selector: row => row.minutes,
      sortable: true,
      center: "true",
    },
    {
      name: 'Situação',
      selector: row => row.active,
      cell: row => <ActiveFrequency active={row.active} />,
      sortable: true,
      center: "true",
    },
    {
      selector: row => row.user_id,
      cell: row => <EditFrequency id={row.frequency_id} />,
      width: "50px"
    },
  ];
  
  const paginationComponentOptions = {
    rowsPerPageText: 'Frequências por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Frequências de horário</h1>
          </div>
          <div>
            {roleUser.includes("OPERATOR") && (
              <NewFrequency onFrequencyCreated={handleFrequencyCreated} />
            )}
          </div>
        </header>
        <section>
          <div className='container-panel'>
            <div className='container-panel-input'>
                <CustomInput
                    label="Pesquisa"
                    type="text"
                    placeholder="Digite o nome que deseja pesquisar"
                    value= {filterText}
                    onChange= {(e) => setFilterText(e.target.value)}
                />
              </div>
          </div>
        </section>

        <section>
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            responsive
            striped
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<div>Nenhuma frequência disponível ou cadastrado</div>}
          />
        </section>
      </Row>
    </Container>
  );
};

export default FrequencyList;