import "./index.scss";

const Spinner = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
        className="loading-spinner"
    >
        <circle cx="25" cy="25" r="20" stroke="#009ED9" strokeWidth="5" fill="none" />
        <circle cx="25" cy="25" r="20" stroke="#E6E6E6" strokeWidth="5" fill="none" strokeDasharray="31.4 31.4" strokeDashoffset="0">
            <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="2.4s"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
);

export default Spinner;