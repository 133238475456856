import React, { useState } from 'react';
import { Modal, Button, Alert} from 'react-bootstrap';
import CustomInput from '../../../../components/input';
import "./index.scss";
import Tools from '../../../../utils/tools';
import LocationService from '../../../../services/location';

const NewLocation = ({ onLocationCreated }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [internal_code, setInternalCode] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  
  const handleNewLocation = async () => {
    let valid = true;
    let erros = {};

    if (!name.trim()) {
      erros.name = 'O nome é obrigatório';
      valid = false;
    }

    if (!internal_code.trim()) {
        erros.internal_code = 'O e-mail é obrigatório';
        valid = false;
    }

    if (!zipcode.trim()) {
      erros.zipcode = 'O CEP é obrigatório';
      valid = false;
    }

    if (!address.trim()) {
      erros.address = 'O endereço é obrigatório';
      valid = false;
    }

    if (!number.trim()) {
      erros.number = 'O número é obrigatório';
      valid = false;
    }

    if (!city.trim()) {
      erros.city = 'A cidade é obrigatório';
      valid = false;
    }

    if (!state.trim()) {
      erros.state = 'O estado é obrigatório';
      valid = false;
    }


    if (valid) {
      try {
          const response = await LocationService.newLocal(
            name, 
            internal_code,
            zipcode,
            address,
            parseInt(number),
            city,
            state
        );
        if (response.status === 200) {
          setAlert(true);
          setName('');
          setInternalCode('');
          setZipCode('');
          setAddress('');
          setNumber('');
          setCity('');
          setState('');

          setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          erros.submit = response.message;
          setErros(erros);
          setAlert(true);
        }
      } catch (error) {
            // Outro erro
          return error;
            //seterroAPIShow(true);
      }
    } else {
      setErros(erros);
    }
    onLocationCreated();
  };

  const handleClose = () => {
    setErros({});
    setName('');
    setInternalCode('');
    setZipCode('');
    setAddress('');
    setNumber('');
    setCity('');
    setState('');
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleCepChange = async (e) => {
    let erros = {};
    const cep = e.target.value;
    setZipCode(cep);

    if (cep.length === 8) {
      try {
        const responseZipCode = await Tools.searchZipCode(cep);
        const data = await responseZipCode.json();

        if (data.erro) {
          erros.zipcode = 'CEP não encontrado';
          setErros(erros);
        } else {
          let completeadress = `${data.logradouro} - ${data.bairro}`;
          setAddress(completeadress)
          setCity(data.localidade);
          setState(data.uf);
          erros.zipcode = null;
          setErros(erros);
        }

      }  catch (err) {
        erros.zipcode = 'Erro ao buscar o CEP';
        setErros(erros);
      }
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Novo local
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo local</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
            <div>
              <CustomInput
                  label="Nome"
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
              {erros.name && <span className='texterror'>{erros.name}</span>}
            </div>
            <div>
              <CustomInput
                  label="Código interno"
                  type="text"
                  placeholder="BR005"
                  value={internal_code}
                  onChange={(e) => setInternalCode(e.target.value)}
              />
              {erros.internal_code && <span className='texterror'>{erros.internal_code}</span>}
            </div>
            <h3>Endereço</h3>
            <div>
              <CustomInput
                  label="CEP"
                  type="text"
                  placeholder="xxxxxxxx"
                  value={zipcode}
                  onChange={handleCepChange}
              />
              {erros.zipcode && <span className='texterror'>{erros.zipcode}</span>}
            </div>
            <div className='container_local_number_address'>
              <div className='container_local_input_100'>
                <CustomInput
                    label="Endereço"
                    type="text"
                    placeholder="xxxxxxxx"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                {erros.address && <span className='texterror'>{erros.address}</span>}
              </div>
              <div>
                <CustomInput
                    label="Número"
                    type="text"
                    placeholder="xxxxxxxx"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                />
                {erros.number && <span className='texterror'>{erros.number}</span>}
              </div>
            </div>
            <div className='container_local_number_address'>
              <div className='container_local_input_100'>
                <CustomInput
                    label="Cidade"
                    type="text"
                    placeholder="xxxxxxxx"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                />
                {erros.city && <span className='texterror'>{erros.city}</span>}
              </div>
              <div>
                <CustomInput
                    label="UF"
                    type="text"
                    placeholder="xxxxxxxx"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                />
                {erros.state && <span className='texterror'>{erros.state}</span>}
              </div>
            </div>
            <div>
              {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Local criado com sucesso!</Alert>}
              {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
          <Button variant="primary" onClick={handleNewLocation}>
            Criar local
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewLocation;