import React, {  useState, useEffect} from 'react';
import AuthService from '../../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import {Container, Row} from 'react-bootstrap';
import CustomInput from '../../components/input';
import NewUser from './component/new_user';
import UserService from '../../services/user';
import DataTable from 'react-data-table-component';
import Convert from '../../utils/convert';
import Breadcrumbs from '../../components/breadcrumbs';
import EditIcon from '../../components/icons/edit';

const UserList = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [dataUsers, setDataUsers] = useState([]);
  const [userAdded, setUserAdded] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [roleUser, setRoleUser] = useState('');

  const filteredItems = dataUsers.filter(item => 
    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) || 
    (item.email && item.email.toLowerCase().includes(filterText.toLowerCase()))
  );


  const handleUserCreated = () => {
    setUserAdded(prev => !prev);
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("MASTER")) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
}, [roleUser, currentUser, navigate]);

useEffect (() => {
  const fetchUsers = async () => {
    const users = await UserService.getUsers();
    if (users) {
      setDataUsers(users.data.data)
    } else {
      console.log('Não foi possível obter os usuários.');
    }
  };

  fetchUsers();
}, [userAdded])

    const EditUser = ({ id }) => (
      <Link to={`/usuarios/${id}`}>
        <EditIcon />
      </Link>
    );

    const columns = [
      {
        name: 'ID',
        selector: row => row.user_id,
        sortable: true,
      },
      {
        name: 'Nome',
        selector: row => row.name,
        sortable: true,
      },
      {
        name: 'Data de criação',
        selector: row => row.create_at,
        cell: row => {
          return row.create_at ? `${Convert.convertDataBR(row.create_at)}` : "-";
        },
        center: "true",
      },
      {
        name: 'Último login em',
        selector: row => row.last_login,
        cell: row => {
          return row.last_login ? `${Convert.convertDataBR(row.last_login)}` : "-";
        },
        center: "true",
      },
      {
        name: 'Data de nascimento',
        selector: row => row.birth_date,
        cell: row => {
          return row.birth_date ? `${Convert.convertDateFormat(row.birth_date)}` : "-";
        },
        center: "true",
      },
      {
        name: 'E-mail',
        selector: row => row.email,
      },
      {
        name: 'Perfis',
        selector: row => row.roles,
        cell: row => Convert.convertRoles(row.roles),
        sortable: true,
      },
    ];

    if (roleUser.includes("MASTER")) {
      columns.push({
          selector: row => row.user_id,
          cell: row => <EditUser id={row.user_id} />,
          width: "50px"
        });
    }
  
    const paginationComponentOptions = {
      rowsPerPageText: 'Locais por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',
    };

    return (
      <div>
        <Container className='container-custom-fluid'>
            <Row>
                <header className='header'>
                    <div>
                        <Breadcrumbs />
                        <h1>Usuários</h1>
                    </div>
                    <div>
                      {roleUser.includes("MASTER")  && (
                          <NewUser onUserCreated={handleUserCreated}/>
                      )}
                    </div>
                </header>
                <section>
                    <div className='container-panel'>
                      <div className='container-panel-input'>
                          <CustomInput
                              label="Pesquisa"
                              type="text"
                              placeholder="Digite o nome ou e-mail que deseja pesquisar"
                              value= {filterText}
                              onChange= {(e) => setFilterText(e.target.value)}
                          />
                        </div>
                    </div>
                </section>

                <section>
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      responsive
                      striped
                      paginationComponentOptions={paginationComponentOptions}
                      noDataComponent={<div>Nenhum usuário disponível ou cadastrado</div>}
                    />
                </section>
            </Row>
        </Container>
      </div>
    );
  };
  
  export default UserList;