import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Button, Alert} from 'react-bootstrap';
import LocationService from '../../../services/location';
import Breadcrumbs from '../../../components/breadcrumbs';
import CustomInput from '../../../components/input';
import UserService from '../../../services/user';
import CustomSelect from '../../../components/select';
import Tools from '../../../utils/tools';

const EditLocation = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const { id } = useParams(); 
  const [name, setName] = useState('');
  const [internal_code, setInternalCode] = useState('');
  const [idcreated, setIdCreated] = useState('');
  const [created, setCreated] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [active, setActive] = useState(false);
  const [successActive, setSuccessActive] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);

  const handleChangeActive = (event) => {
    setActive(event.target.value);
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
        if (roleUser.length === 0 && roleUser.includes("MASTER")) {
            navigate('/');
        }
    } else if (!currentUser) {
      navigate('/login');
    } else {
      const fetchLocal = async () => {
        const local = await LocationService.Location(id);
        if(local.status === 200) {
          setErros({});
          setName(local.data.data.name);
          setInternalCode(local.data.data.internal_code);
          setIdCreated(local.data.data.create_by);
          setZipCode(local.data.data.zip_code);
          setAddress(local.data.data.address);
          setNumber(local.data.data.number);
          setCity(local.data.data.city);
          setState(local.data.data.state);
          setActive(local.data.data.active);
        } else {
          setErros("Erro nos dados");
        }
      }

      fetchLocal();
    }
  }, [roleUser, currentUser, navigate, id]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await UserService.User(idcreated);
      if (user.status === 200) {
        setCreated(user.data.data.name);
      }
    }

    fetchUser();
  }, [idcreated]);

  const optionsActive = [
    { label: 'Ativo', value: true },
    { label: 'Desativado', value: false },
  ];

  const handleStatusLocation = async () => {
    let erros = {};
    try {
        const response = await LocationService.EditStatus(
            id, 
            active
      );
      if (response.status === 200) {
        setErros({});
        setSuccessActive(true);

        setTimeout(() => {
          setSuccessActive(false);
        }, 3000);
      } else {
        erros.submit = response.message;
      }
    } catch (error) {
      erros.submit = error.message;
    }
    
    if(erros.submit) {
      setErros(erros);
      setSuccessActive(true)
    }
  }

  const handleCepChange = async (e) => {
    let erros = {};
    const cep = e.target.value;
    setZipCode(cep);

    if (cep.length === 8) {
      try {
        const responseZipCode = await Tools.searchZipCode(cep);
        const data = await responseZipCode.json();

        if (data.erro) {
          erros.zipcode = 'CEP não encontrado';
          setErros(erros);
        } else {
          let completeadress = `${data.logradouro} - ${data.bairro}`;
          setAddress(completeadress);
          setCity(data.localidade);
          setState(data.uf);
          erros.zipcode = null;
          setErros(erros);
        }

      }  catch (err) {
        erros.zipcode = 'Erro ao buscar o CEP';
        setErros(erros);
      }
    }
  };

  const handleEditLocation = async () => {
    let valid = true;
    let erros = {};

    if (!internal_code.trim()) {
        erros.internal_code = 'O e-mail é obrigatório';
        valid = false;
    }

    if (!zipcode.trim()) {
      erros.zipcode = 'O CEP é obrigatório';
      valid = false;
    }

    if (!address.trim()) {
      erros.address = 'O endereço é obrigatório';
      valid = false;
    }

    if (!String(number).trim()) {
      erros.number = 'O número é obrigatório';
      valid = false;
    }

    if (!city.trim()) {
      erros.city = 'A cidade é obrigatório';
      valid = false;
    }

    if (!state.trim()) {
      erros.state = 'O estado é obrigatório';
      valid = false;
    }


    if (valid) {
      try {
          const response = await LocationService.EditLocation(
            id, 
            internal_code,
            zipcode,
            address,
            parseInt(number),
            city,
            state
        );
        if (response.status === 200) {
          setAlert(true);
          setErros([]);

          setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          erros.submit = response.message;
          setErros(erros);
          setAlert(true);
        }
      } catch (error) {
        erros.submit = error.message;
      }
    } else {
      setErros(erros);
    }
  };

  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
              <Breadcrumbs />
              <h1>{name}</h1>
          </div>
        </header>
        <section>
          <div className='container-panel'>
            <h2>Dados gerais</h2>
            <div className='container-panel-input'>
                <div>
                    <CustomInput
                        label="Nome"
                        type="text"
                        value={name}
                        readOnly={true}
                        onChange= {(e) => setName(e.target.value)}
                    />
                </div>
                <div className='input_g'>
                    <CustomInput
                        label="Código interno"
                        type="text"
                        value={internal_code}
                        onChange= {(e) => setInternalCode(e.target.value)}
                    />
                    {erros.internal_code && <span className='texterror'>{erros.internal_code}</span>}
                </div>
                <div className='input_g'>
                    <CustomInput
                        label="Criado por"
                        type="text"
                        value={created}
                        readOnly={true}
                        onChange= {(e) => setCreated(e.target.value)}
                    />
                </div>
              </div>
            <h2>Dados de endereço</h2>
            <div className='container-panel-input'>
                <div className='input_m'>
                    <CustomInput
                        label="CEP"
                        type="text"
                        value={zipcode}
                        onChange= {handleCepChange}
                    />
                    {erros.zipcode && <span className='texterror'>{erros.zipcode}</span>}
                </div>
                <div>
                    <CustomInput
                        label="Endereço"
                        type="text"
                        value={address}
                        onChange= {(e) => setAddress(e.target.value)}
                    />
                    {erros.address && <span className='texterror'>{erros.address}</span>}
                </div>
                <div className='input_p'>
                    <CustomInput
                        label="Número"
                        type="text"
                        value={number}
                        onChange= {(e) => setNumber(e.target.value)}
                    />
                    {erros.number && <span className='texterror'>{erros.number}</span>}
                </div>
                <div className='input_m'>
                    <CustomInput
                        label="Cidade"
                        type="text"
                        value={city}
                        onChange= {(e) => setCity(e.target.value)}
                    />
                    {erros.city && <span className='texterror'>{erros.city}</span>}
                </div>
                <div className='input_p'>
                    <CustomInput
                        label="Estado"
                        type="text"
                        value={state}
                        onChange= {(e) => setState(e.value)}
                    />
                    {erros.state && <span className='texterror'>{erros.state}</span>}
                </div> 
              </div>
              <div className='container-alerts'>
                {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Local alterado com sucesso!</Alert>}
                {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
              </div>
              {roleUser.includes("MASTER") && (
                <div className='container-panel-button'>
                    <Button variant="primary" onClick={handleEditLocation}>
                        Salvar alterações
                    </Button>
                </div>
              )}
            </div>
            <div className='container-panel'>
              <h2>Situação do local</h2>
              <div className='container-panel-select'>
                <div>
                    <CustomSelect 
                      label="Situação" 
                      options={optionsActive} 
                      value={active} 
                      onChange={handleChangeActive} 
                    />
                    {erros.name && <span className='texterror'>{erros.name}</span>}
                </div>
              </div>
              <div className='container-alerts'>
                {successActive && !erros.submit && <Alert variant="success" onClose={() => setSuccessActive(false)} dismissible show={successActive}>Status alterados com sucesso!</Alert>}
                {successActive && erros.submit && <Alert variant="danger" onClose={() => setSuccessActive(false)} dismissible show={successActive}>{erros.submit}</Alert>}
              </div>
              {roleUser.includes("MASTER")&& (
                <div className='container-panel-button'>
                    <Button variant="primary" onClick={handleStatusLocation}>
                        Alterar situação
                    </Button>
                </div>
              )}
            </div>
        </section>
      </Row>
    </Container>
  );
};

export default EditLocation;