import { Breadcrumb } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '../icons/home';
import "./index.scss";
import Convert from '../../utils/convert';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><HomeIcon /></Breadcrumb.Item>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        const exceptionLevels = name !== "editor" && name !== "gerenciarpermissaooperador" && name !== "processo" && name !== "elemento";

        return isLast ? (
          <Breadcrumb.Item active key={name} aria-current="page">
            {Convert.convertBreadcrumbs(name)}
          </Breadcrumb.Item>
        ) : (
          exceptionLevels ? (
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: routeTo }} key={name}>
              {Convert.convertBreadcrumbs(name)}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={name} className="breadcrumb-item-disabled">
              { Convert.convertBreadcrumbs(name)}
            </Breadcrumb.Item>
          )
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;