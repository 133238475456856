import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Button, Form, Alert} from 'react-bootstrap';
import CustomInput from '../../../components/input';
import UserService from '../../../services/user';
import Validate from '../../../utils/validate';
import Breadcrumbs from '../../../components/breadcrumbs';
import AllocateService from '../../../services/allocate';
import DataTable from 'react-data-table-component';
import Loading from '../../../components/loading';
import PasswordInput from '../../../components/passaword_input';
import LocationService from '../../../services/location';
import ReactSelect from 'react-select';

const EditUser = () => {
    const navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    const { id } = useParams(); 
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [birth_date, setBirthDate] = useState('');
    const [admission_date, setAdmissionDate] = useState('');
    const [sap_entry, setSapEntry] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [initialRoles, setInitialRoles] = useState([]);
    const [selectedRoleOption, setSelectedRoleOption] = useState({
        MASTER: false,
        EDITOR: false,
        OPERATOR: false,
        ANALYST: false,
     });
    const [successRole, setsuccessRole] = useState(false);
    const [successUser, setsuccessUser] = useState(false);
    const [successUserPassword, setSuccessUserPassword] = useState(false);
    const [successAllocate, setsuccessAllocate] = useState(false);
    const [erros, setErros] = useState({});
    const [roleUser, setRoleUser] = useState('');
    const [dataLocations, setDataLocations] = useState([]);
    const [dataUserFlowcharts, setDataUserFlowcharts] = useState([]);
    const [dataSelectLocations, setSelectLocations] = useState([]);
    const [alocation, setaLocation] = useState([]);

    const [loading, setLoading] = useState(false);
    
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setSelectedRoleOption((prevState) => ({
           ...prevState,
           [value]: checked,
        }));
     };

     const fetchLocations = async (callback) => {
        try {
            const allocations = await AllocateService.getAllocatsUser(id);
            if (allocations.status === 200) {
                setDataLocations(allocations.data.data);
    
                // Call the callback if provided
                if (typeof callback === "function") {
                    callback(allocations.data.data);
                }
            } else {
                console.error("Failed to fetch allocations:", allocations.statusText);
            }
        } catch (error) {
            console.error("Error fetching allocations:", error);
        }
    };

    useEffect(() => {
        const fetchRole = async () => {
          try {
            const role = await AuthService.verifyRole();
            if (role.status === 200) {
              const level = role.data.data.roles;
              setRoleUser(level);
            } else {
              navigate('/login');
            }
          } catch (error) {
            console.error("Failed to fetch role:", error);
            navigate('/login');
          }
        };
    
        fetchRole();
    }, [navigate]);

    useEffect(() => {
        if (currentUser && roleUser) {
          if (roleUser.length === 0 && roleUser.includes("MASTER")) {
            navigate('/');
          }
        } else if (!currentUser) {
          navigate('/login');
        } else {
            const fetchUsers = async () => {
                const user = await UserService.User(id);
                if (user.status === 200) {
                    setName(user.data.data.name);
                    setEmail(user.data.data.email);
                    setBirthDate(user.data.data.birth_date);
                    setAdmissionDate(user.data.data.admission_date);
                    setSapEntry(user.data.data.sap_entry);
                    setInitialRoles(user.data.data.roles);

                    setSelectedRoleOption({
                        MASTER: user.data.data.roles.includes("MASTER"),
                        EDITOR: user.data.data.roles.includes("EDITOR"),
                        OPERATOR: user.data.data.roles.includes("OPERATOR"),
                        ANALYST: user.data.data.roles.includes("ANALYST"),
                    });
                } else {
                    navigate('/');
                }
              };
    
            fetchUsers();

            fetchLocations();

            const fetchSelectLocations = async () => {
                const location = await LocationService.getLocations();
                if (location) {
                  let localSelect = location.data.data;
                  let transformedLocations = localSelect.map(location => ({
                    label: location.name,
                    value: location.local_id,
                  }));
                  setSelectLocations(transformedLocations)
                } else {
                  setErros('Não foi possível obter os usuários.');
                }
            };

            fetchSelectLocations();

            const fetchUserFlowchart = async () => {
                const userFlowchart = await UserService.UserFlowchart(id);
                if(userFlowchart.status === 200) {
                    const  userflow = userFlowchart.data.data.flowcharts;
                    console.log("userflow: ", userflow)
                    if (Array.isArray(userflow)) {
                        setDataUserFlowcharts(userflow.map(item => item.flowchart));
                    }
                } 
            };

            fetchUserFlowchart();
        }
    }, [roleUser, currentUser, navigate, id, initialRoles, dataLocations, fetchLocations]);

    const handleEditRole = async () => {
        let valid = true;
        let erros = {};
        setErros({});
        const selectedRoles = Object.keys(selectedRoleOption).filter(
            (role) => selectedRoleOption[role]
        );
        if(selectedRoles.length <= 0) {
            erros.role = 'O usuário precisa de pelo menos um nível de permissão';
            valid = false;
        }
        if(valid) {
            try {
                setLoading(true);
                const response = await UserService.editUserRole(
                    id, 
                    selectedRoles
            );

            if (response.status === 200) {
                setsuccessRole(true);

                setTimeout(() => {
                    setsuccessRole(false);
                }, 3000);
                setLoading(false);
            } 
            } catch (error) {
                // Outro erro
                //setErrorAPI(`Erro ao fazer login: ${error.message}`);
                //seterroAPIShow(true);
            }
        } else {
            setErros(erros);
        }
        setLoading(false);
    }

    const handleEditUser = async () => {
        let valid = true;
        let erros = {};

        if (!name.trim()) {
            erros.name = 'O nome é obrigatório';
            valid = false;
        }

        if (!email.trim()) {
            erros.email = 'O e-mail é obrigatório';
            valid = false;
        } else if (!Validate.validateEmail(email)) {
            erros.email = 'O e-mail não é válido';
            valid = false;
        }

        if (valid) {
            setLoading(true);
            try {
                const response = await UserService.editUser(
                    id, 
                    name,
                    email,
                    birth_date,
                    admission_date,
                    sap_entry
                );

                if (response.status === 200) {
                    setsuccessUser(true);

                    setTimeout(() => {
                        setsuccessUser(false);
                    }, 3000);
                    setLoading(false);
                }  else {

                }
            } catch (error) {

            }
        } else {
            setErros(erros);
        }
        setLoading(false);
    }

    const handleEditUserPassword = async () => {
        let valid = true;
        let erros = {};
    
        if (!password1.trim()) {
            erros.password1 = 'A nova senha é obrigatória';
            valid = false;
        }
    
        if (password1.length < 8) {
            erros.password1 = 'A nova senha deve ter pelo menos 8 caracteres';
            valid = false;
        }
    
        if (!/[A-Z]/.test(password1)) {
            erros.password1 = 'A nova senha precisa ter ao menos um caracter maiúsculo';
            valid = false;
        }
    
        if (!/[0-9]/.test(password1)) {
            erros.password1 = 'A nova senha precisa ter ao menos um número';
            valid = false;
        }
    
        if (!/[@!$#]/.test(password1)) {
            erros.password1 = 'A nova senha precisa ter ao menos um caracter especial (@, !, $, #)';
            valid = false;
        }
    
        if (!password2.trim()) {
            erros.password2 = 'Confirmar nova senha é obrigatório';
            valid = false;
        }
    
        if (password1 !== password2) {
            erros.password2 = 'As senhas não coincidem';
            valid = false;
        }
    
        if (valid) {
            setLoading(true);
            try {
                const response = await UserService.editUserPassword(
                    id,
                    password1,
                    password2
                );
    
                if (response.status === 200) {
                    setSuccessUserPassword(true);
                    setErros({});
    
                    setTimeout(() => {
                        setSuccessUserPassword(false);
                    }, 3000);
                }
            } catch (error) {
                console.error('Erro ao editar a senha:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setErros(erros);
        }
    };

    const handleAllocate = async () => {
        setLoading(true);
        let erros = {};
        let locals = alocation.map(item => item);
    
        try {
            const response = await AllocateService.newAllocate(
                parseInt(id),
                locals
            );
            if (response.status === 200) {
              setsuccessAllocate(true);
              setaLocation([]);
              fetchLocations();
    
              setLoading(false);
              setTimeout(() => {
                setsuccessAllocate(false);
              }, 3000);
            } else {
              erros.submit = response.message;
            }
        } catch (error) {
            erros.submit = error.message.data;
            setLoading(false);
        } 
    }
    

    const isMultic = false;

    const handleChange = (selectedOptions) => {
        const values = Array.isArray(selectedOptions)
            ? selectedOptions.map(option => option.value) // Multi-select: Map to values array
            : selectedOptions?.value                    // Single-select: Get the value directly
    
        // Convert to an array to keep `alocation` consistent
        setaLocation(Array.isArray(values) ? values : [values].filter(Boolean));
    };
    const columns = [
        {
          name: 'ID',
          selector: row => row.local_id,
          sortable: true,
          width: "100px",
        },
        {
          name: 'Nome',
          selector: row => row.local_name,
          sortable: true,
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Locais por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const columnsFlowchart = [
        {
          name: 'ID',
          selector: row => row.flowchart_id,
          sortable: true,
          width: "100px",
        },
        {
          name: 'Nome',
          selector: row => row.name,
          sortable: true,
        },
    ];

    const paginationFlowchart = {
        rowsPerPageText: 'Processos por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
  
    return (
      <div>
        <Container className='container-custom-fluid'>
            {loading && (
                <Loading /> 
            )}
            <Row>
                <header className='header'>
                    <div>
                        <Breadcrumbs />
                        <h1>{name}</h1>
                    </div>
                </header>
                <section>
                    <div className='container-panel'>
                        <h2>Dados gerais</h2>
                        <div className='container-panel-input'>
                            <div>
                                <CustomInput
                                    label="Nome"
                                    type="text"
                                    value={name}
                                    onChange= {(e) => setName(e.target.value)}
                                />
                                {erros.name && <span className='texterror'>{erros.name}</span>}
                            </div>
                            <div>
                                <CustomInput
                                    label="E-mail"
                                    type="text"
                                    placeholder=""
                                    value={email}
                                    onChange= {(e) => setEmail(e.target.value)}
                                />
                                {erros.email && <span className='texterror'>{erros.email}</span>}
                            </div>
                        </div>
                        <div className='container-panel-date'>
                            <div>
                                <CustomInput
                                    label="Data de nascimento"
                                    type="date"
                                    placeholder=""
                                    value={birth_date}
                                    onChange= {(e) => setBirthDate(e.target.value)}
                                />
                                {erros.birth_date && <span className='texterror'>{erros.birth_date}</span>}
                            </div>
                            <div>
                                <CustomInput
                                    label="Data de admissão"
                                    type="date"
                                    placeholder=""
                                    value={admission_date}
                                    onChange= {(e) => setAdmissionDate(e.target.value)}
                                />
                                {erros.admission_date && <span className='texterror'>{erros.admission_date}</span>}
                            </div>
                            <div>
                                <CustomInput
                                    label="Matrícula SAP"
                                    type="text"
                                    placeholder=""
                                    value={sap_entry}
                                    onChange= {(e) => setSapEntry(e.target.value)}
                                />
                                {erros.sap_entry && <span className='texterror'>{erros.sap_entry}</span>}
                            </div>
                        </div>
                        <div className='container-alerts'>
                            {successUser &&  <Alert variant="success" onClose={() => setsuccessUser(false)} dismissible show={successUser}>Dados de usuários alterados com sucesso!</Alert>}
                        </div>
                        {roleUser.includes("MASTER") && (
                            <div className='container-panel-button' onClick={handleEditUser}>
                                <Button variant="primary">
                                    Salvar alterações
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className='container-panel'>
                        <h2>Níveis de acesso</h2>
                        <div className='container-panel-input'>
                            <Form.Check 
                                type="checkbox"
                                label="Master"
                                name="radioRoleOptions"
                                value="MASTER"
                                checked={selectedRoleOption.MASTER}
                                onChange= {handleCheckboxChange}
                            />
                            <Form.Check 
                                type="checkbox"
                                label="Editor"
                                name="radioRoleOptions"
                                value="EDITOR"
                                checked={selectedRoleOption.EDITOR}
                                onChange= {handleCheckboxChange}
                            />
                            <Form.Check 
                                type="checkbox"
                                label="Operador"
                                name="radioRoleOptions"
                                value="OPERATOR"
                                checked={selectedRoleOption.OPERATOR}
                                onChange= {handleCheckboxChange}
                            />
                            <Form.Check 
                                type="checkbox"
                                label="Analista"
                                name="radioRoleOptions"
                                value="ANALYST"
                                checked={selectedRoleOption.ANALYST}
                                onChange= {handleCheckboxChange}
                            />
                        </div>
                        {erros.role && <span className='texterror'>{erros.role}</span>}
                        <div className='container-alerts'>
                            {successRole &&  <Alert variant="success" onClose={() => setsuccessRole(false)} dismissible show={successRole}>Nível de acesso alterado com sucesso!</Alert>}
                        </div>
                        {roleUser.includes("MASTER") && (
                            <div className='container-panel-button'>
                                <Button variant="primary" onClick={handleEditRole}>
                                    Alterar nível de acesso
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className='container-panel'>
                        <h2>Alteração de senha</h2>
                        <div className='container-panel-input'>
                            <div>
                                <PasswordInput
                                    label="Nova senha"
                                    value={password1}
                                    onChange= {(e) => setPassword1(e.target.value)}
                                    />
                                {erros.password1 && <span className='texterror'>{erros.password1}</span>}
                            </div>
                            <div>
                                <PasswordInput
                                    label="Confirmar nova senha"
                                    value={password2}
                                    onChange= {(e) => setPassword2(e.target.value)}
                                    />
                                {erros.password2 && <span className='texterror'>{erros.password2}</span>}
                            </div>
                        </div>
                        <div className='container-alerts'>
                            {successUserPassword &&  <Alert variant="success" onClose={() => setSuccessUserPassword(false)} dismissible show={successUserPassword}>Senha alterada com sucesso!</Alert>}
                        </div>
                        {roleUser.includes("MASTER") && (
                            <div className='container-panel-button' onClick={handleEditUserPassword}>
                                <Button variant="primary">
                                    Alterar senha
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className='container-panel'>
                        <h2>Locais Atribuídos</h2>
                        <DataTable
                            columns={columns}
                            data={dataLocations}
                            pagination
                            responsive
                            striped
                            paginationComponentOptions={paginationComponentOptions}
                            noDataComponent={<div>Nenhum local atribuído a esse usuário</div>}
                        />
                    </div>

                    <div className='container-panel'>
                        <h2>Alocar usuário</h2>
                        <div className='select'>
                        <label>Local</label>
                            <ReactSelect 
                            isMulti={isMultic} // Suporte a múltiplos selecionados
                            options={dataSelectLocations} 
                            value={dataSelectLocations.filter(option => Array.isArray(alocation) && alocation.includes(option.value))} // Mapeia os valores selecionados
                            onChange={handleChange} 
                            placeholder="Digite para buscar..."
                            />
                        </div>
                        <div className='container-alerts'>
                            {successAllocate &&  <Alert variant="success" onClose={() => setsuccessAllocate(false)} dismissible show={successAllocate}>Usuário alocado com sucesso!</Alert>}
                        </div>
                        {roleUser.includes("MASTER") && (
                            <div className='container-panel-button' onClick={handleAllocate}>
                                <Button variant="primary">
                                    Alocar usuário
                                </Button>
                            </div>
                        )}
                    </div>

                    <div className='container-panel'>
                        <h2>Processos Atribuídos</h2>
                        <DataTable
                            columns={columnsFlowchart}
                            data={dataUserFlowcharts}
                            pagination
                            responsive
                            striped
                            paginationComponentOptions={paginationFlowchart}
                            noDataComponent={<div>Nenhum processo atribuído a esse usuário</div>}
                        />
                    </div>
                </section>
            </Row>
        </Container>
      </div>
    );
  };
  
  export default EditUser;