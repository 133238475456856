import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Button, Alert} from 'react-bootstrap';
import Breadcrumbs from '../../../components/breadcrumbs';
import CustomInput from '../../../components/input';
import UserService from '../../../services/user';
import CustomSelect from '../../../components/select';
import MeasurementService from '../../../services/measurement';
import "./index.scss";

const EditMeasure = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const { id } = useParams(); 
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [idcreated, setIdCreated] = useState ('');
  const [created, setCreated] = useState('');
  const [active, setActive] = useState('');
  const [useMov, setUseMov] = useState('');
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);

  const [dataMeasure, setDataMeasure] = useState(null);

  const handleChangeActive = (event) => {
    setActive(event.target.value);
  };

  const handleChangeUseMov = (event) => {
    setUseMov(event.target.value);
  };

  useEffect(() => {
    const storedProps = localStorage.getItem('measurement');
    if (storedProps) {
      setDataMeasure(JSON.parse(storedProps));
    }

  }, [navigate]);

  useEffect(() => {
    const handleSearch = () => {
      if (dataMeasure && dataMeasure.length > 0) {
        const result = dataMeasure.find(item => item.measurement_id === parseInt(id));
        setName(result.name);
        setSymbol(result.symbol);
        setIdCreated(result.create_by);
        setActive(result.active);
        setUseMov(result.use_in_moviment);
      }
    };

    handleSearch();
  }, [id, dataMeasure]);

  

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
        if (roleUser.length === 0 && roleUser.includes("MASTER")) {
            navigate('/');
        }
    } else if (!currentUser) {
      navigate('/login');
    }
    
  }, [roleUser, currentUser, navigate, id]);

  useEffect(() => {
    if(idcreated && idcreated > 0) {
      const fetchUser = async () => {
        const user = await UserService.User(idcreated);
        if (user.status === 200) {
          setCreated(user.data.data.name);
        }
      }

      fetchUser();
    }
  }, [idcreated]);


  const optionsActive = [
    { label: 'Ativo', value: true },
    { label: 'Desativado', value: false },
  ];
  
  const optionsUseMov= [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  const handleEdit = () => {
    const updatedData = dataMeasure.map(item => {
      if (item.measurement_id === parseInt(id)) {
        return { ...item, active: active, use_in_moviment: useMov}; // Atualize o campo desejado aqui
      }
      return item;
    });

    // Salve o array atualizado de volta no localStorage
    localStorage.setItem("measurement", JSON.stringify(updatedData));
    setDataMeasure(updatedData); // Atualize o estado local
  };

  const handleStatusFrequency = async () => {
    let erros = {};

    try {
        const response = await MeasurementService.EditMeasurement(
            id, 
            active,
            useMov
      );
      if (response.status === 200) {
        setErros([]);
        handleEdit();
        setAlert(true);

        setTimeout(() => {
          setAlert(false);
        }, 3000);
      } else {
        erros.submit = response.message;
      }
    } catch (error) {
      erros.submit = error.message;
    }
    
    if(erros.submit) {
      setErros(erros);
      setAlert(true)
    }
  }

  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
              <Breadcrumbs />
              <h1>{name}</h1>
          </div>
        </header>
        <section>
          <div className='container-panel'>
            <h2>Dados gerais</h2>
            <div className='container-panel-input'>
                <div> 
                    <CustomInput
                        label="Nome"
                        type="text"
                        value={name}
                        readOnly={true}
                        onChange= {(e) => setName(e.target.value)}
                    />
                </div>
                <div className='input_m'>
                    <CustomInput
                        label="Criado por"
                        type="text"
                        value={created}
                        readOnly={true}
                        onChange= {(e) => setCreated(e.target.value)}
                    />
                </div>
                <div className='input_p'>
                    <CustomInput
                        label="Símbolo"
                        type="text"
                        value={symbol}
                        readOnly={true}
                        onChange= {(e) => setSymbol(e.target.value)}
                    />
                </div>
              </div>
              
              <div className='container-panel-select container-panel-mov'>
                <div>
                      <CustomSelect 
                        label="Usar unidade de medida em movimentação de lote" 
                        options={optionsUseMov} 
                        value={useMov} 
                        onChange={handleChangeUseMov} 
                      />
                      {erros.active && <span className='texterror'>{erros.active}</span>}
                </div>
                <div>
                      <CustomSelect 
                        label="Situação" 
                        options={optionsActive} 
                        value={active} 
                        onChange={handleChangeActive} 
                      />
                      {erros.active && <span className='texterror'>{erros.active}</span>}
                </div>
              </div>
              <div className='container-alerts'>
                {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Unidade de medida alterada com sucesso!</Alert>}
                {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
              </div>
              {roleUser.includes("MASTER") && (
                <div className='container-panel-button'>
                    <Button variant="primary" onClick={handleStatusFrequency}>
                        Salvar alterações
                    </Button>
                </div>
              )}
            </div>
        </section>
      </Row>
    </Container>
  );
};

export default EditMeasure;