import React, { useCallback, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '../../../components/breadcrumbs';
import { Alert, Container, Row } from 'react-bootstrap';
import "./index.scss";
import BpmnModeler from "bpmn-js/lib/Modeler";
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer';
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import CustomContextPadModule from "./context-pad";
import CustomPalette from './palette';
import "./index.scss";
import EditProperties from './element';
import AuthService from '../../../services/auth.service';
import {useNavigate, useParams } from 'react-router-dom';
import FlowchartService from '../../../services/flowchart';
import Convert from '../../../utils/convert';
import Loading from '../../../components/loading';

const Editor = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const { id } = useParams(); 
  const canvasRef = useRef(null);
  const modelerRef = useRef(null);
  const [showEditProperties, setShowEditProperties] = useState(false);
  const [dataFlow, setDataFlow] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [elementId, setElementId] = useState('');
  const [elementType, setElementType] = useState('');
 
  const [name, setName] = useState('');
  const [xml, setXml] = useState('');

  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  const handleShow = () => setShowEditProperties(true);
  const handleClose = () => setShowEditProperties(false);


  useEffect(() => {
    localStorage.removeItem("removeItems");
    localStorage.removeItem("connectItems");
    
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();

    const storedProps = localStorage.getItem('flowchart');

    if (storedProps) {
        setDataFlow(JSON.parse(storedProps));
    }
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate, id]);


  useEffect(() => {
    const handleSearch = () => {
      // Carrega dados do localStorage ou do estado inicial
      const storedFlow = dataFlow || JSON.parse(localStorage.getItem('flowchart')) || [];

      if (storedFlow.length > 0) {
        const result = storedFlow.find(item => item.flowchart_id === parseInt(id));
        if (result) {
          setName(result.name);
          setXml(result.xml);
        } else {
          console.warn('Flowchart não encontrado!');
        }
      }
    };

    handleSearch(); // Chama a função de busca

    // Salva a lista no localStorage (caso dataFlow venha da API)
    if (dataFlow && dataFlow.length > 0) {
      localStorage.setItem('flowchart', JSON.stringify(dataFlow));
    }

  }, [dataFlow, id, navigate]); 

  const fetchFlowchartElement =  useCallback(async () => {
    try {
      const flowchartElement = await FlowchartService.getFlowchartElement(id);
      if (flowchartElement) {
        let flowchartElements = flowchartElement.data.data;
        if (flowchartElements) {
            setActive(flowchartElements.active)
            localStorage.setItem('flowchartElements', JSON.stringify(flowchartElements.elements));
            localStorage.setItem('flowchartConnects', JSON.stringify(flowchartElements.connections));
        }
      } else {
        setErros('Não foi possível obter os elementos.');
      }
    } catch (error) {
      console.error("Não foi possível obter os elementos.", error);
    }
  }, [id]);
  
  useEffect(() => {
    fetchFlowchartElement();
  },[fetchFlowchartElement]);

  const isElementIdInExtraData = (element_id) => {
    const elements = JSON.parse(localStorage.getItem('flowchartElements')) || [];
    
    // Retorna o elemento correspondente ou null se não encontrado
    const foundElement = elements.find(
      (element) => element.extra_data?.element_prid === element_id
    );
  
    return foundElement ? foundElement.element_id : null; // Retorna o element_id ou null
  };

  const handleSaveProcess = useCallback(async () => {
      try {
        const xmlsave  = await modelerRef.current.saveXML();
        const response = await FlowchartService.updateFlowchartEdit(
          parseInt(id),
          String(xmlsave.xml)
        );

        if (response.status === 200) {
           setAlert(true);
           setXml(xmlsave.xml);

           const flowcharts = JSON.parse(localStorage.getItem('flowchart')) || [];
           const updatedFlowcharts = flowcharts.map(flowchart => {
            
            if (flowchart.flowchart_id === parseInt(id)) {
              return { ...flowchart, xml: xmlsave.xml };
            }
            return flowchart; // Retorna o fluxo inalterado caso o ID não corresponda
          });
        
          localStorage.setItem('flowchart', JSON.stringify(updatedFlowcharts));

          setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          erros.submit = response.message;
        }
      } catch (err) {
        console.error('Erro ao salvar o diagrama:', err);
      }


    if(erros.submit) {
      setErros(erros);
      setAlert(true)
    }
  }, [id, erros]);

  const handlesaveElement = useCallback(async (nameTask, element_id, type) => {
    setLoading(true);
    let erros = {};
    let element_type = Convert.convertTypeElement(type);
    let element_prid = { element_prid: element_id };
  
    try {
      const response = await FlowchartService.newElement(
        parseInt(id),
        element_prid,
        isElementIdInExtraData(element_id) ? isElementIdInExtraData(element_id) : parseInt(''),
        nameTask,
        element_type,
        [],
        null,
        true,
      );
  
      if (response.status === 200) {
        fetchFlowchartElement();
        handleSaveProcess();
        setLoading(false);
        setErros({});
      } 
    } catch (err) {
      erros.submit = `Erro ao salvar o elemento: ${err}`;
      setErros(erros);
    }
  }, [id, fetchFlowchartElement, handleSaveProcess]);

  const handledeleteShape = useCallback(async (element_id) => {
    setLoading(true);
    let erros = {};
    const storedElements = JSON.parse(localStorage.getItem('flowchartElements')) || [];

    let id_shape = storedElements.find(el => el.extra_data.element_prid === element_id);

    try {
      const response = await FlowchartService.newElement(
          parseInt(id_shape.flowchart_id),
          id_shape.extra_data,
          parseInt(id_shape.element_id),
          id_shape.name,
          id_shape.element_type,
          id_shape.properties,
          id_shape.description,
          false,
          id_shape.extra_data,
      );

      if (response.status === 200) {
        fetchFlowchartElement();
        handleSaveProcess();
        setLoading(false);
        setErros({});
      } 
    } catch (err) {
        erros.submit('Erro ao salvar o elemento:', err);
    }
    setLoading(false)
  }, [fetchFlowchartElement, handleSaveProcess]);

  const handlesaveConnect = useCallback(async (sourceId, element_id, targetId) => {
    setLoading(true);
    let erros = {};
    let element_prid = { element_prid: element_id };
    const storedElements = JSON.parse(localStorage.getItem('flowchartElements')) || [];

    let id_start = storedElements.find(el => el.extra_data.element_prid === sourceId);
    let id_target = storedElements.find(el => el.extra_data.element_prid === targetId);
  
    try {
      const response = await FlowchartService.newConnect(
          parseInt(id),
          parseInt(id_start.element_id),
          parseInt(id_target.element_id),
          element_prid
      );

      if (response.status === 200) {
        fetchFlowchartElement();
        handleSaveProcess();
        setLoading(false);
        setErros({});
      } 
    } catch (err) {
        erros.submit('Erro ao salvar o elemento:', err);
    }
  }, [id, fetchFlowchartElement, handleSaveProcess]);

  const handledeleteConnect = useCallback(async (element_id) => {
    setLoading(true);
    let erros = {};
    let element_prid = { element_prid: element_id };
    const storedConnects = JSON.parse(localStorage.getItem('flowchartConnects')) || [];

    let id_connection = storedConnects.find(el => el.extra_data.element_prid === element_id);
    
    if(id_connection) {
      try {
        const response = await FlowchartService.updateConnect(
            parseInt(id),
            parseInt(id_connection.connection_id),
            false,
            element_prid
        );

        if (response.status === 200) {
          fetchFlowchartElement();
          handleSaveProcess();
          setLoading(false);
          setErros({});
        } 
      } catch (err) {
          erros.submit('Erro ao salvar o elemento:', err);
      }
    }
    setLoading(false);
  }, [id, fetchFlowchartElement, handleSaveProcess]);

  useEffect(() => {
    setLoading(true);
    if (!canvasRef.current) {
      console.error('canvasRef.current não está definido');
      return;
    }

      // Limpeza da instância anterior
    if (modelerRef.current) {
      modelerRef.current.destroy();
      modelerRef.current = null;
    }
      
    if(active) {
      modelerRef.current = new BpmnModeler({
        container: canvasRef.current,
        additionalModules: [
          CustomContextPadModule,
          CustomPalette
        ]
      });
    } else {
      modelerRef.current = new BpmnViewer({
        container: canvasRef.current,
      })
    }

    if (!modelerRef.current) {
      console.error('modelerRef.current não está definido');
      return;
    }

    // Função para carregar ou criar o diagrama
    const loadDiagram = async () => {
      setLoading(true);
      if (!modelerRef.current) {
        console.error('modelerRef.current não está definido');
        setLoading(false);
        return;
      }

      try {
        if (!xml) {
          await modelerRef.current.createDiagram();
          setLoading(false);
        } else {
          await modelerRef.current.importXML(xml);
          setLoading(false);
        }
      } catch (err) {
        console.error('Erro ao carregar ou criar o diagrama:', err);
        setLoading(false);
      }
    };

    loadDiagram();

    const eventBus = modelerRef.current.get('eventBus');

    eventBus.on('shape.changed', (event) => {
      const { element } = event;
      if ((element.type === 'bpmn:Task' || element.type === "bpmn:StartEvent" || element.type === "bpmn:EndEvent") && element.businessObject.name) {
        let taskName = element.businessObject || 'No name provided';
        handlesaveElement(taskName.name, taskName.id, taskName.$type);
      }
    });

    const handleElementClick = (event) => {
      const { element } = event;
      if (element.type === 'bpmn:Task' || element.type === "bpmn:StartEvent" || element.type === "bpmn:EndEvent") {
        let taskName = element.businessObject || 'No name provided';
        if(taskName.name) {
          setTaskName(taskName.name);
          setElementId(taskName.id);
          setElementType(taskName.$type);
          handleShow(); 
        }     
      }
    };

    eventBus.on("connect.end", (event) => {
      const { connection } = event.context;

      const sourceName = connection.source.di.bpmnElement.name;
      const targetName = connection.target.di.bpmnElement.name;
      const connectionId = connection.id;
      const sourceId = connection.source.id;
      const targetId = connection.target.id;

      const modeling = modelerRef.current.get('modeling');
      if (!modeling) {
        console.error("Modeling não está disponível.");
        return;
      }
      
      if ((!sourceName || sourceName === undefined) || (!targetName || targetName === undefined)) {
        // Cancele a conexão se algum dos elementos não tiver um nome
        let erros = {};
        erros["delete"] = "Para criar uma conexão os dois elementos necessitam de um nome";
        setErros(erros);
        setAlert(true)
        event.preventDefault();
      } else {
        handlesaveConnect(sourceId, connectionId, targetId);
      }
    });

    eventBus.on("shape.remove", (event) => {
      const { element } = event;
      const shapeId = event.element.id;
        if (element.type === 'bpmn:Task' || element.type === "bpmn:StartEvent" || element.type === "bpmn:EndEvent") {
          let taskName = element.businessObject || 'No name provided';
          if(taskName.name) {
            handledeleteShape(shapeId);
          } else {
            handleSaveProcess();
          }
        }
    });

    eventBus.on("connection.remove", (event) => {
      
      const connectionId = event.element.id;

      handledeleteConnect(connectionId);
    });
    
    eventBus.on('element.click', handleElementClick);

    return () => {
      if (eventBus) {
        eventBus.off('element.click', handleElementClick);
      }
      if (modelerRef.current) {
        modelerRef.current.destroy();
      }
    };
  }, [xml, navigate, fetchFlowchartElement, handlesaveElement, handlesaveConnect, handledeleteConnect, handledeleteShape, handleSaveProcess, active]);



  return (
    <Container className='container-custom-fluid'>
      <Row >
        {loading && (
          <Loading /> 
        )}
        <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>{name}</h1>
          </div>
        </header>
        <div ref={canvasRef} className="container-editor"  />
        {!active && (
          <h5 className='container_editor_avisos'>Processos desativados não podem ser editados</h5>
        )}

        {roleUser.includes("EDITOR") && (
          <>
            <div className='container-alerts container_alerts_edit'>
              {alert && !erros.submit && !erros.delete && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Processo salvo com sucesso!</Alert>}
              {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
              {alert && erros.delete && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.delete}</Alert>}
            </div>
            <div className='container-panel-button save_process'>
            </div>
            <EditProperties show={showEditProperties} handleClose={handleClose} nameTask={taskName} element_id={elementId} type={elementType} processActive={active}/>
          </>
        )}
      </Row>
    </Container>
  );
}

export default Editor;
