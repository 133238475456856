import React, { useEffect, useState } from 'react';
import AuthService from '../../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Container, Row } from 'react-bootstrap';
import Breadcrumbs from '../../components/breadcrumbs';
import CustomInput from '../../components/input';
import DataTable from 'react-data-table-component';
import FlowchartService from '../../services/flowchart';
import NewFlowchart from './component/new_flowchart';
import Convert from '../../utils/convert';
import LocationService from '../../services/location';
import EditIcon from '../../components/icons/edit';
import ProcessEditIcon from '../../components/icons/process_edit';
import UserOperadorIcon from '../../components/icons/user_plus_operador';
import UserService from '../../services/user';

const FlowchartList = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataFlowchart, setDataFlowchart] = useState([]);
  const [vFlowchart, setvFlowchart] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [dataLocations, setDataLocations] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);

  const filteredItems = dataFlowchart.filter(item => 
    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleFlowchartCreated = () => {
    setvFlowchart(prev => !prev);
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && (roleUser.includes("MASTER") || roleUser.includes("EDITOR"))) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  useEffect(() => {
    const fetchFlowcharts = async () => {
      const flowchart = await FlowchartService.getFlowcharts();
      if (flowchart) {
        setDataFlowchart(flowchart.data.data);
        localStorage.setItem('flowchart', JSON.stringify(flowchart.data.data));
      } else {
        console.log('Não foi possível obter os usuários.');
      }
    };
  
    fetchFlowcharts();

    const fetchLocations = async () => {
      const location = await LocationService.getLocations();
      if (location) {
        let localSelect = location.data.data;
        let transformedLocations = localSelect.map(location => ({
          label: location.name,
          value: location.local_id,
        }));
        setDataLocations(transformedLocations)
      }
    };

    fetchLocations();
  }, [vFlowchart]);

  useEffect (() => {
    const fetchUsers = async () => {
      const users = await UserService.getUsers();
      if (users) {
        setDataUsers(users);
      } else {
        console.log('Não foi possível obter os usuários.');
      }
    };
    fetchUsers();
  }, []);

  const ActiveFlowchart = ({ active }) => (
    <Badge bg={active ? "success" : "danger"}>{active ? "Ativo" : "Desativado"}</Badge>
  );

  const EditProcess = ({ id }) => (
    <Link to={`/processos/${id}`}>
      <EditIcon />
    </Link>
  );

  const CreateProcess = ({ id }) => (
    <Link to={`/processos/editor/${id}`}>
      <ProcessEditIcon />
    </Link>
  );

  const AllocateOperador = ({ id }) => (
    <Link to={`/processos/gerenciarpermissaooperador/${id}`}>
      <UserOperadorIcon />
    </Link>
  );
  
  console.log("dataUsers: " , dataUsers)

  const columns = [
    {
      name: 'ID',
      selector: row => row.flowchart_id,
      sortable: true,
    },
    {
      name: 'Nome',
      selector: row => row.name,
      sortable: true,
    },
    {
       name: 'Criado em',
       selector: row => row.create_at,
       cell: row => Convert.convertDataBR(row.create_at),
       sortable: true,
       center: "true",
    },
    // {
    //     name: 'Criado por',
    //     selector: row => row.create_at,
    //     cell: row => {
    //       const user = dataUsers.find(user => user.user_id === row.create_by);
    //       return user ? `${user.name} às ${Convert.convertDataBR(row.create_at)}` : '';
    //     },
    //     sortable: true,
    //     center: "true",
    // },
    // {
    //     name: 'Editado por',
    //     selector: row => row.edited_at,
    //     cell: row => {
    //       const user = dataUsers.find(user => user.user_id === row.edited_by);
    //       return user ? `${user.name} às ${Convert.convertDataBR(row.edited_at)}` : '';
    //     },
    //     sortable: true,
    //     center: "true",
    // },
    {
      name: 'Local',
      selector: row => row.local_id,
      cell: row => {
        const foundItem = dataLocations.find(item => item.value === row.local_id);
        return foundItem ? foundItem.label : row.local_id;
      },
      sortable: true,
    },
    {
      name: 'Situação',
      selector: row => row.active,
      cell: row => <ActiveFlowchart active={row.active} />,
      sortable: true,
      center: "true",
    },
  ];

  if (roleUser.includes("MASTER")) {
    columns.push({
      selector: row => row.user_id,
      cell: row => <EditProcess id={row.flowchart_id} />,
      width: "50px",
    });
  }

  if (roleUser.includes("EDITOR")) {
    columns.push({
      selector: row => row.user_id,
      cell: row => <AllocateOperador id={row.flowchart_id} />,
      width: "50px",
    });

    columns.push({
      selector: row => row.user_id,
      cell: row => <CreateProcess id={row.flowchart_id} />,
      width: "50px",
    });
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Processos por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const ExpandedComponent = ({ data }) => {
    return (
        <div className='container_datatable_expanded'>
            <p><strong>Descrição:</strong> {data.description}</p>
        </div>
    );
  };

  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Processos</h1>
          </div>
          <div>
            {roleUser.includes("MASTER") && (
              <NewFlowchart onFlowchartCreated={handleFlowchartCreated} />
            )}
          </div>
        </header>

        <section>
          <div className='container-panel'>
            <div className='container-panel-input'>
                <CustomInput
                    label="Pesquisa"
                    type="text"
                    placeholder="Digite o nome que deseja pesquisar"
                    value= {filterText}
                    onChange= {(e) => setFilterText(e.target.value)}
                />
              </div>
          </div>
        </section>

        
        <section>
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            responsive
            striped
            paginationComponentOptions={paginationComponentOptions}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            noDataComponent={<div>Nenhum processo disponível ou cadastrado</div>}
          />
        </section>
      </Row>
    </Container>
  );
};

export default FlowchartList;