import api from './api';
import TokenService from './token.service';

const FlowchartService = {
    getFlowcharts: async () => {
        try {
            const response = await api
            .get('/flowchart/', {
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
            });
    
            if (response.status === 200) {
                return response;
            } 
        } catch (error) {
          return error.response?.data;
        }
    },
    newFlowchart: async (name: string, description: string, local_id: number) => {
        try {
            const response = await api
            .post('/flowchart/', {
                name,
                description,
                local_id
            },{
              headers: {
                'Authorization': `Token ${TokenService.getUser()}`,
              }
            });
    
            if (response.status === 200) {
                return response;
            } 
        } catch (error) {
          return error.response?.data;
        }
    },
    updateFlowchartEdit: async (flow_id: number, xml_data: string ) => {
      try {
          const response = await api
          .post(`/flowchart/${flow_id}/`, {
            xml_data
          }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
          });
  
          if (response.status === 200) {
              return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },
    updateFlowchart: async (flow_id: number, name: string, description: string, local_id: number, activate: boolean) => {
      try {
          const response = await api
          .put(`/flowchart/${flow_id}/`, {
            name,
            description,
            local_id,
            activate
          }, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
          });
  
          if (response.status === 200) {
              return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },
    newElement: async (flowchart_id, extra_data, element_id: number , name: string, element_type: string, properties, description: string,  active: boolean) => {
      try {
        const response = await api
        .post(`/flowchart/${flowchart_id}/element/`, {
          element_id,
          name,
          description,
          element_type,
          active,
          extra_data,
          properties
        },{
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        console.log(response)

        if (response.status === 200) {
            return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    getFlowchartElement: async (flowchart_id) => {
        try {
          const response = await api
          .get(`/flowchart/${flowchart_id}/element/`, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
          });

          if (response.status === 200) {
              return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },
    newConnect: async (flowchart_id, element_origin_id: number, element_destiny_id: number, extra_data) => {
      try {
        const response = await api
        .post(`/flowchart/${flowchart_id}/connection/`, {
          element_destiny_id,
          element_origin_id,
          extra_data
        },{
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    updateConnect: async (flowchart_id, connection_id, active: boolean, extra_data) => {
      try {
        const response = await api
        .put(`/flowchart/${flowchart_id}/connection/${connection_id}/`, {
          active,
          extra_data
        },{
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    createPermissions: async (flowchart_id, user_id) => {
      try {
        const response = await api
        .post(`/inputs/permission/flowchart/${flowchart_id}/${user_id}/`, {},{
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    getFlowchartPermissions: async (flowchart_id) => {
      try {
        const response = await api
        .get(`/inputs/permission/flowchart/${flowchart_id}/`, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
    deleteFlowchartPermissions: async (flowchart_id, user_id) => {
      try {
          const response = await api
          .delete(`/inputs/permission/flowchart/${flowchart_id}/${user_id}/`, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
          });
  
          if (response.status === 200) {
              return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
    },
    getElementID: async (flowchart_id, element_id) => {
      try {
        const response = await api
        .get(`/flowchart/${flowchart_id}/element/${element_id}/`, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
      } catch (error) {
        return error.response?.data;
      }
    },
};

export default FlowchartService;