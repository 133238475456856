import api from './api';
import TokenService from './token.service';

const InputsService = {
  getFlowchartPermissionsUser: async (user_id) => {
      try {
          const response = await api
          .get(`/inputs/permission/user/${user_id}/`, {
            headers: {
              'Authorization': `Token ${TokenService.getUser()}`,
            }
          });
  
          if (response.status === 200) {
              return response;
          } 
      } catch (error) {
        return error.response?.data;
      }
  },
  getElementsFlowchart: async (flowchart_id) => {
    try {
        const response = await api
        .get(`/flowchart/${flowchart_id}/element/`, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
    } catch (error) {
      return error.response?.data;
    }
  },
  getEventsFlowchart: async (flowchart_id) => {
    try {
        const response = await api
        .get(`/inputs/production_event/${flowchart_id}/`, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
    } catch (error) {
      return error.response?.data;
    }
  },
  getElementsProdutionFlowchart: async (flowchart_id) => {
    try {
        const response = await api
        .get(`/inputs/production_event/${flowchart_id}/`, {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
    } catch (error) {
      return error.response?.data;
    }
  },
  openElementProcess: async (flowchart_id, element_id, inputs) => {
    try {
        const response = await api
        .post(`/inputs/production_event/${flowchart_id}/${element_id}/`, {
          inputs
        },
        {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
    } catch (error) {
      return error.response?.data;
    }
  },
  openCurrent: async (flowchart_id, element_id, event_id, quantity: number, name: string, measurement_id: number) => {
    try {
        const response = await api
        .post(`/inputs/production_event/${flowchart_id}/${element_id}/${event_id}/output/`, {
          quantity,
          name,
          measurement_id
        },
        {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
    } catch (error) {
      return error.response?.data;
    }
  },
  currentConnections: async (flowchart_id, element_id) => {
    try {
        const response = await api
        .get(`/inputs/production_event/${flowchart_id}/${element_id}/connections/`,
        {
          headers: {
            'Authorization': `Token ${TokenService.getUser()}`,
          }
        });

        if (response.status === 200) {
            return response;
        } 
    } catch (error) {
      return error.response?.data;
    }
  },
  getElement: async (flowchart_id, element_id, event_id) => {
    try {
      const response = await api
      .get(`inputs/production_event/${flowchart_id}/${element_id}/${event_id}/`,
      {
        headers: {
          'Authorization': `Token ${TokenService.getUser()}`,
        }
      });

      if (response.status === 200) {
          return response;
      } 
    } catch (error) {
      return error.response?.data;
    }
  },
  closeEvent: async (flowchart_id, element_id, event_id) => {
    try {
      const response = await api
      .put(`inputs/production_event/${flowchart_id}/${element_id}/${event_id}/`, {},
      {
        headers: {
          'Authorization': `Token ${TokenService.getUser()}`,
        }
      });

      if (response.status === 200) {
          return response;
      } 
    } catch (error) {
      return error.response?.data;
    }
  },
  getFormData: async (flowchart_id, element_id, event_id) => {
    try {
      const response = await api
      .get(`inputs/production_event/${flowchart_id}/${element_id}/${event_id}/data/`, 
      {
        headers: {
          'Authorization': `Token ${TokenService.getUser()}`,
        }
      });

      if (response.status === 200) {
          return response;
      } 
    } catch (error) {
      return error.response?.data;
    }
  },
  includeInputData: async (flowchart_id, element_id, event_id, property_id, value) => {
    try {
      const response = await api
      .post(`inputs/production_event/${flowchart_id}/${element_id}/${event_id}/data/`, {
        property_id,
        value
      },
      {
        headers: {
          'Authorization': `Token ${TokenService.getUser()}`,
        }
      });

      if (response.status === 200) {
          return response;
      } 
    } catch (error) {
      return error.response?.data;
    }
  },
  editInputData: async (flowchart_id, element_id, event_id, input_id, property_id, value) => {
    try {
      const response = await api
      .put(`inputs/production_event/${flowchart_id}/${element_id}/${event_id}/data/${input_id}/`, {
        property_id,
        value
      },
      {
        headers: {
          'Authorization': `Token ${TokenService.getUser()}`,
        }
      });

      if (response.status === 200) {
          return response;
      } 
    } catch (error) {
      return error.response?.data;
    }
  },
}

export default InputsService;