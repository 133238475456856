export default class CustomContextPad {
  constructor(contextPad, elementRegistry, modeling, connect, dragging, eventBus) {
    contextPad.registerProvider(this);
    this._elementRegistry = elementRegistry;
    this._modeling = modeling;
    this._connect = connect;
    this._dragging = dragging;
    this._eventBus = eventBus;
  }

  getContextPadEntries(element) {
    return (entries) => {
      // Remove as entradas indesejadas
      delete entries["append.end-event"];
      delete entries["append.subprocess"];
      delete entries["append.gateway"];
      delete entries["append.intermediate-event"];
      delete entries['append.change-element'];
      delete entries['append.text-annotation'];
      delete entries['append.append-task'];
      delete entries['replace'];
      // delete entries['delete'];
      this._eventBus.on('canvas.viewbox.changed', () => {
        // go ahead and re-position popup menu
        console.log("Change View");
      })

      return entries;
    };
  }
}

CustomContextPad.$inject = ["contextPad", "elementRegistry", "modeling",  "connect", "dragging", "eventBus"];
