import React, { useState, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import "./index.scss";
import Convert from '../../../../../../utils/convert';
import CardUp from '../../../../../../components/icons/card_up';
import CardDown from '../../../../../../components/icons/card_down';
import EditIcon from '../../../../../../components/icons/edit';
import FrequencyService from '../../../../../../services/frequency';
import MeasurementService from '../../../../../../services/measurement';
import CustomInput from '../../../../../../components/input';
import TextArea from '../../../../../../components/textarea';
import CustomSelect from '../../../../../../components/select';
import { useParams } from 'react-router-dom';

const CardList = ({ element_id, refresh, setRefresh, editCard }) => {
  const [dataCard, setDataCard] = useState([]);
  const [measures, setDataMeasures] = useState([]);
  const [frequencys, setDataFrequencys] = useState([]);
  const [showInactive, setShowInactive] = useState(true);

  const getProperties = () => {
    setDataCard('');
    const savedData = localStorage.getItem('properties');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setDataCard(parsedData);
    } else {
      setDataCard([]);
    }
  };

  useEffect(() => {
    getProperties();

    const fetchLocations = async () => {
      const measure = await MeasurementService.getMeasurements();
      if (measure) {
        let measureSelect = measure.data.data;
        let transformedMeasure = measureSelect
        .filter(measure => measure.active)
        .map(measure => ({
          label: measure.name,
          value: measure.measurement_id,
        }));
        setDataMeasures(transformedMeasure);
      } else {
        console.log('Não foi possível obter os usuários.');
      }
    };

    fetchLocations();

    const fetchUsers = async () => {
      const frequency = await FrequencyService.getFrequencys();
      if (frequency) {
        let frequencySelect = frequency.data.data;
        let transformedFrequency = frequencySelect
        .filter(frequency => frequency.active)
        .map(frequency => ({
          label: frequency.name,
          value: frequency.frequency_id,
        }));
        setDataFrequencys(transformedFrequency);
      } else {
        console.log('Não foi possível obter os usuários.');
      }
    };

    fetchUsers();
  }, [element_id]);

  useEffect(() => {
    getProperties();
  }, [refresh]);

  const getMeasurementName = (value) => {
    const measurement_name = measures.find(item => item.value === value);
    return measurement_name ? measurement_name.label : 'Desconhecido';
  };

  const getFrequencyName = (value) => {
    const frequency_name = frequencys.find(item => item.value === value);
    return frequency_name ? frequency_name.label : 'Desconhecido';
  };

  const handleToggleChange = () => {
    setShowInactive(!showInactive);
  };

  return (
    <div className="container_cards">
      <label className="toggle-switch">
      <input
        type="checkbox"
        checked={showInactive}
        onChange={handleToggleChange}
      />
      <span className="slider"></span>
      <span className="toggle-label">
        Exibir desativados
      </span>
    </label>

      {dataCard.length > 0 ? (
        dataCard
          .filter(item => showInactive || item.active)
          .sort((a, b) => (b.active ? 1 : -1))
          .map((item, index) => {
          const labelMeasurement = getMeasurementName(item.measurement_id);
          const labelFrequency = getFrequencyName(item.frequency_id);

          return (
            <Card
              key={index}
              data={{ ...item, measurement_name: labelMeasurement, frequency_name: labelFrequency }}
              measures={measures}
              frequencys={frequencys}
              element_id={element_id}
              refresh={refresh}
              setRefresh={setRefresh}
              editCard={editCard}
            />
          );
        })
      ) : (
        <p>Nenhuma propriedade disponível ou encontrada.</p>
      )}
    </div>
  );
};

const Card = ({ data, measures, frequencys, refresh, setRefresh, editCard}) => {
  const {
    property_id,
    element_id,
    name,
    description,
    min_value,
    max_value,
    lower_specification,
    upper_specification,
    measurement_name,
    frequency_name,
    data_type,
    active,
  } = data;
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [erros, setErros] = useState({});
  const { id } = useParams();

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing ? true : false);
  };

  const updatePropertyInLocalStorage = (propertyId, updatedProperties) => {
    console.log(propertyId)
    // Obter as propriedades existentes do localStorage
    let propertiesData = localStorage.getItem('properties');
    let propertiesDataArray = propertiesData ? JSON.parse(propertiesData) : [];
  
    // Atualizar o item com o property_id correspondente
    const updatedPropertiesArray = propertiesDataArray.map(property => 
      property.property_id === propertyId ? { ...property, ...updatedProperties } : property
    );
  
    // Salvar o array atualizado de volta no localStorage
    localStorage.setItem('properties', JSON.stringify(updatedPropertiesArray));
    
    console.log('Propriedade atualizada com sucesso.');
  };

  const handleSave = () => {
    let valid = true;
    let erros = {};

    
    if (!editedData.name.trim()) {
        erros.name = 'O nome é obrigatório';
        valid = false;
    }

    if (parseInt(editedData.max_value) < parseInt(editedData.min_value)) {
        erros.max_value = 'O valor máximo não pode ser menor que o valor mínimo';
        valid = false;
    }

    if (!editedData.measurement_id) {
        erros.measures = 'A unidade de medida é obrigatório';
        valid = false;
    }

    if (!editedData.frequency_id) {
        erros.frequencys = 'A frequência é obrigatório';
        valid = false;
    }

    if (!editedData.data_type) {
        erros.data_type = 'O tipo de dado é obrigatório';
        valid = false;
    }
    
    if (valid) {
        setErros([]);

        let updateProperties = {
            "property_id": editedData.property_id,
            'element_id': element_id,
            'flowchart_id': id,
            'name': editedData.name,
            'description':  editedData.description,
            'min_value':  editedData.min_value ? editedData.min_value : null,
            'max_value':  editedData.max_value ? editedData.max_value : null,
            'lower_specification':  editedData.lower_specification ? editedData.lower_specification : null,
            'upper_specification':  editedData.upper_specification ? editedData.upper_specification : null,
            'measurement_id':  editedData.measurement_id,
            'frequency_id':  editedData.frequency_id,
            'data_type':  editedData.data_type,
            'active':  editedData.active
        }
        updatePropertyInLocalStorage(property_id, updateProperties);

        setRefresh(prev => !prev);
    } else {
        setErros(erros);
    }
    setIsEditing(false); 
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target || e; 
  
    if (name) {
      setEditedData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      console.error("Campo sem 'name' foi alterado:", e);
    }
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const optionsDataType = [
    { label: 'Booleano', value: "BOOL" },
    { label: 'Data', value: "DATE" },
    { label: 'Data e hora', value: "DATETIME" },
    { label: 'Número', value: "NUMBER"},
    { label: 'Número inteiros', value: "INTEGER"},
    { label: 'Texto', value: "TEXT" },
  ];

  const optionsActive = [
    { label: 'Ativo', value: true },
    { label: 'Desativado', value: false },
  ];

  return (
    <div className={`card ${!active ? 'inactive-card' : ''}`}>
      <div className="card-header">
        <h3>{name}</h3>
        <div className='card-header-actions'>
        {editCard && (
          <div onClick={toggleEdit}> {property_id ? <EditIcon  /> : ""} </div>
        )}
          <div onClick={toggleExpand}>{!isExpanded ? <CardDown /> : <CardUp />}</div>
        </div>
      </div>
      {isExpanded && (
        <div className="card-body card_edit">
          {isEditing ? (
            <>
              <div className="container_element_inputs">
                <div>
                  <CustomInput
                      label="Nome"
                      type="text"
                      name="name"
                      placeholder="Nome"
                      value= {editedData.name}
                      onChange={handleChangeInput}
                  />
                  {erros.name && <span className='texterror'>{erros.name}</span>}
                </div>
                <div>
                      <TextArea 
                          label="Descrição"
                          name="description"
                          value={editedData.description}
                          onChange={handleChangeInput}
                          placeholder="Descrição"
                          rows={2}
                      />
                </div>
                <div className="container_element_inputs__two">
                  <div>
                    <CustomInput
                        label="Valor minímo"
                        type="number"
                        placeholder="-7"
                        name="min_value"
                        value= {editedData.min_value}
                        onChange= {handleChangeInput}
                    />
                    {erros.min_value && <span className='texterror'>{erros.min_value}</span>}
                  </div>
                  <div>
                    <CustomInput
                        label="Valor máximo"
                        type="number"
                        placeholder="100"
                        name="max_value"
                        value= {editedData.max_value}
                        onChange= {handleChangeInput}
                    />
                    {erros.max_value && <span className='texterror'>{erros.max_value}</span>}
                  </div>
                </div>
                <div className="container_element_inputs__two">
                    <div>
                        <CustomInput
                            label="Especificação inferior"
                            type="text"
                            placeholder=""
                            name="lower_specification"
                            value= {editedData.lower_specification}
                            onChange= {handleChangeInput}

                        />
                        {erros.lower_specification && <span className='texterror'>{erros.lower_specification}</span>}
                    </div>
                    <div>
                        <CustomInput
                            label="Especificação superior"
                            type="text"
                            placeholder=""
                            name="upper_specification"
                            value= {editedData.upper_specification}
                            onChange= {handleChangeInput}
                        />
                        {erros.upper_specification && <span className='texterror'>{erros.upper_specification}</span>}
                    </div>
                </div>
              </div>
              <div className='container-panel-select'>
                <CustomSelect
                    label="Unidades de medidas" 
                    options={measures} 
                    name="measurement_id"
                    value={editedData.measurement_id ? editedData.measurement_id : ""} 
                    onChange= {handleChangeSelect}
                />
                {erros.measures && <span className='texterror'>{erros.measures}</span>}
              </div>
              <div className='container-panel-select'>
                <CustomSelect
                    label="Frequência" 
                    name="frequency_id"
                    options={frequencys} 
                    value={editedData.frequency_id ? editedData.frequency_id : ""} 
                    onChange= {handleChangeSelect}
                />
                {erros.frequencys && <span className='texterror'>{erros.frequencys}</span>}
              </div>
              <div className='container-panel-select'>
                <CustomSelect
                    label="Tipo de dado" 
                    name="data_type"
                    options={optionsDataType} 
                    value={editedData.data_type ? editedData.data_type : ''} 
                    onChange= {handleChangeSelect}
                />
                {erros.data_type && <span className='texterror'>{erros.data_type}</span>}
              </div>
              <div className='container-panel-select'>
                <CustomSelect
                    label="Situação" 
                    name="active"
                    options={optionsActive} 
                    value={editedData.active} 
                    onChange= {handleChangeSelect}
                />
            </div>

            <div className='container-panel-button save_process'>
              <Button variant="primary" onClick={handleSave}>
                Alterar propriedade
              </Button>
            </div> 
            </>
          ) : (
            <>
              <p>{description}</p>  
              <p><strong>Valor mínimo:</strong> {min_value}</p>
              <p><strong>Valor máximo:</strong> {max_value}</p>
              <p><strong>Especificação inferior:</strong> {lower_specification}</p>
              <p><strong>Especificação superior:</strong> {upper_specification}</p>
              <p><strong>Unidades de medidas:</strong> {measurement_name}</p> 
              <p><strong>Frequências:</strong> {frequency_name}</p>
              <p><strong>Tipo de dado:</strong> {Convert.convertOptionsDataType(data_type)}</p>
              <p><strong>Ativo:</strong> <Badge bg={active ? "success" : "danger"}>{active ? "Ativo" : "Desativado"}</Badge></p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CardList;
