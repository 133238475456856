import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Button, Alert} from 'react-bootstrap';
import Breadcrumbs from '../../../components/breadcrumbs';
import CustomInput from '../../../components/input';
import CustomSelect from '../../../components/select';
import TextArea from '../../../components/textarea';
import LocationService from '../../../services/location';
import FlowchartService from '../../../services/flowchart';

const EditFlowchart = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const { id } = useParams(); 
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [local_id, setLocalId] =  useState('');
  const [dataLocations, setDataLocations] = useState([]);
  const [active, setActive] = useState('');
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);

  const [dataFlow, setDataFlow] = useState(null);

  const handleChangeActive = (event) => {
    setActive(event.target.value);
  };

  const handleChangeLocal = (event) => {
    setLocalId(event.target.value);
  };


  useEffect(() => {
    const storedProps = localStorage.getItem('flowchart');
    if (storedProps) {
      setDataFlow(JSON.parse(storedProps));
    }

  }, [navigate]);

  useEffect(() => {
    const handleSearch = () => {
      if (dataFlow && dataFlow.length > 0) {
        const result = dataFlow.find(item => item.flowchart_id === parseInt(id));
        setName(result.name);
        setDescription(result.description);
        setActive(result.active);
        setLocalId(result.local_id);
      }
    };

    handleSearch();
  }, [id, dataFlow]);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0) {
        navigate('/');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate, id]);

  useEffect(() => {
    const fetchLocations = async () => {
      const location = await LocationService.getLocations();
      if (location) {
        let localSelect = location.data.data;
        let transformedLocations = localSelect
        .filter(location => location.active)
        .map(location => ({
          label: location.name,
          value: location.local_id,
        }));
        setDataLocations(transformedLocations)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };

    fetchLocations();
  }, [navigate]);

  const optionsActive = [
    { label: 'Ativo', value: true },
    { label: 'Desativado', value: false },
  ];
  
  const handleEdit = () => {
    let local = local_id;
    let activate = active;
    
    const updatedData = dataFlow.map(item => {
      if (item.flowchart_id === parseInt(id)) {
        return { ...item, name: name, description: description, local_id: local, active: activate }; 
      }
      return item;
    });

    // Salve o array atualizado de volta no localStorage
    localStorage.setItem("flowchart", JSON.stringify(updatedData));
    setDataFlow(updatedData); // Atualize o estado local
  };

  const handleFlowchart = async () => {
    let valid = true;
    let erros = {};
    // let local = local_id.value ? local_id.value : local_id;
    // let activate = active.value ? active.target.value : active;

    if (!name.trim()) {
      erros.name = 'O nome é obrigatório';
      valid = false;
    }

    // if (local_id === '' || local_id === undefined) {
    //   erros.local = 'O local é obrigatório';
    //   valid = false;
    // }

    if (!local_id) {
      erros.local = 'O local é obrigatório';
      valid = false;
    } else {
      const selectedLocation = dataLocations.find(loc => loc.value === local_id);
      if (!selectedLocation) {
        erros.local = 'O local selecionado está inativo. Selecione um local ativo.';
        valid = false;
      }
    }

    if (active === '' || active === undefined) {
      erros.active = 'O situação é obrigatório';
      valid = false;
    }

    if (valid) {
      try {
          const response = await FlowchartService.updateFlowchart(
              id, 
              name,
              description,
              local_id,
              active,
        );
        if (response.status === 200) {
          setErros({});
          handleEdit();
          setAlert(true);

          setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          erros.submit = response.message;
        }
      } catch (error) {
        erros.submit = error.message;
      }
    } else {
      setErros(erros);
    }
    
    if(erros.submit) {
      setErros(erros);
      setAlert(true)
    }
  }

  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
              <Breadcrumbs />
              <h1>{name}</h1>
          </div>
        </header>
        <section>
          <div className='container-panel'>
            <h2>Dados gerais</h2>
                <div className='container-panel-input'>
                    <div> 
                        <CustomInput
                            label="Nome"
                            type="text"
                            value={name}
                            onChange= {(e) => setName(e.target.value)}
                        />
                        {erros.name && <span className='texterror'>{erros.name}</span>}
                    </div> 
                </div>
                <div className='container-panel-input'>
                    <div>
                        <TextArea 
                            label="Descrição"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Descrição"
                            rows={3}
                        />
                    </div>
                </div>
                <div className='container-panel-select'>
                    <div className='input_g'>
                        <CustomSelect 
                            label="Local" 
                            options={dataLocations} 
                            value={local_id} 
                            onChange={handleChangeLocal} 
                        />
                        {erros.local && <span className='texterror'>{erros.local}</span>}
                    </div>
                    <div className='input_p'>
                        <CustomSelect 
                        label="Situação" 
                        options={optionsActive} 
                        value={active} 
                        onChange={handleChangeActive} 
                        />
                        {erros.active && <span className='texterror'>{erros.active}</span>}
                    </div>
                </div>
                <div className='container-alerts'>
                    {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Fluxograma alterado com sucesso!</Alert>}
                    {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
                </div>
                <div className='container-panel-button'>
                  <Button variant="primary" onClick={handleFlowchart}>
                      Salvar alterações
                  </Button>
                </div>
            </div>
        </section>
      </Row>
    </Container>
  );
};

export default EditFlowchart;