import Select from 'react-select';
import "./index.scss";

function CustomInput({ 
  options, 
  onChange, 
  value, 
  label, 
  isMultic = false, 
  name, 
  readonly 
}) {
  const handleChange = (selectedOption) => {
    onChange({
      target: {
        name: name || "", 
        value: isMultic
          ? selectedOption?.map(option => option.value) || [] // Para múltiplas opções
          : selectedOption ? selectedOption.value : "" // Para seleção única
      }
    });
  };

  return (
    <div className='select'>
      {label && <label>{label}</label>}
      <Select
        isMulti={isMultic}
        value={
          isMultic
            ? options.filter(option => value?.includes(option.value)) || [] 
            : options.find(option => option.value === value) || null
        }
        onChange={handleChange}
        options={options}
        placeholder="Digite para buscar..."
        isSearchable={true}
        name={name || null}
        isDisabled={readonly}
        isClearable // Permite limpar o campo
      />
    </div>
  );
}

export default CustomInput;
