import React, { useState } from 'react';
import { Modal, Button, Alert} from 'react-bootstrap';
import CustomInput from '../../../../components/input';
import PasswordInput from '../../../../components/passaword_input';
import "./index.scss";
import UserService from '../../../../services/user';
import Validate from '../../../../utils/validate';

const NewUser = ({ onUserCreated }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [erros, setErros] = useState({});
  const [success, setsuccess] = useState(false);
  //const [errorAPI, setErrorAPI] =  useState('');
  //const [erroAPIShow, seterroAPIShow] = useState(true);

  const handleNewUser = async () => {
    let valid = true;
    let erros = {};

    if (!name.trim()) {
      erros.name = 'O nome é obrigatório';
      valid = false;
    }

    if (!email.trim()) {
        erros.email = 'O e-mail é obrigatório';
        valid = false;
    } else if (!Validate.validateEmail(email)) {
        erros.email = 'O e-mail não é válido';
        valid = false;
    }

    const erroPasswordTest = Validate.validatePassword(password1);
    if (erroPasswordTest.length > 0) {
        erros.password1 = erroPasswordTest.join(' ');
        valid = false;
    }

    if (!password2.trim()) {
        erros.password2 = 'Confirme a sua senha';
        valid = false;
    } else if (password1 !== password2) {
        erros.password2 = 'As senhas não coincidem';
        valid = false;
    }

    if (valid) {
      try {
          const response = await UserService.newUser(
            name, 
            email,
            password1,
            password2
        );
        if (response.data) {
          setsuccess(true);
          setName('');
          setEmail('');
          setPassword1('');
          setPassword2('');
          onUserCreated();

          setTimeout(() => {
            setsuccess(false);
          }, 3000);
        }
      } catch (error) {
            // Outro erro
            //setErrorAPI(`Erro ao fazer login: ${error.message}`);
            //seterroAPIShow(true);
      }
    } else {
      setErros(erros);
    }
  };

  const handleClose = () => {
    setErros({});
    setName('');
    setEmail('');
    setPassword1('');
    setPassword2('');
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Novo usuário
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
            <div>
              <CustomInput
                  label="Nome"
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
              {erros.name && <span className='texterror'>{erros.name}</span>}
            </div>
            <div>
              <CustomInput
                  label="E-mail"
                  type="text"
                  placeholder="nome@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
              />
              {erros.email && <span className='texterror'>{erros.email}</span>}
            </div>
            <div>
              <PasswordInput
                    label="Senha"
                    placeholder="Digite sua senha"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                />
                {erros.password1 && <span className='texterror'>{erros.password1}</span>}
            </div>
            <div>
              <PasswordInput
                  label="Confirmar senha"
                  placeholder="Digite sua senha"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
              />
              {erros.password2 && <span className='texterror'>{erros.password2}</span>}
            </div>
            <div>
              {success &&  <Alert variant="success" onClose={() => setsuccess(false)} dismissible show={success}>Usuário criado com sucesso!</Alert>}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
          <Button variant="primary" onClick={handleNewUser}>
            Criar usuário
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewUser;