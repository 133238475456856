import React, { useEffect, useMemo, useState } from 'react';
import "./index.scss";
import { Alert, Button, Form } from 'react-bootstrap';
import CompleteCheck from '../../../../components/icons/complete_check';
import { useNavigate } from 'react-router-dom';
import InputsService from '../../../../services/inputs';
import Loading from '../../../../components/loading';
import Convert from '../../../../utils/convert';

const DynamicStepForm = ({ steps, flowchart_id, namePropeties}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const activeSteps = useMemo(() => {
    return Array.isArray(steps) ? steps.filter(step => step.active) : [];
  }, [steps]);

  function initializeFormData(steps) {
    if (Array.isArray(steps) && steps.length > 0) {
      return steps.reduce((acc, step) => {
        if (step.name && step.property_id !== undefined && step.value !== undefined) {
          acc[step.name] = { property_id: step.property_id, value: step.value, data_id: step.data_id, active: step.active };
        }
        return acc;
      }, {});
    }

    return {};
  }

  useEffect(() => {
    const data = initializeFormData(activeSteps);
    setFormData(data); // Atualiza o estado com os dados processados
  }, [steps, activeSteps]); // O efeito será executado sempre que `steps` mudar

  const [formData, setFormData] = useState(() => initializeFormData(activeSteps));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, propertyId, dataId) => {
    const { name, value } = e.target;
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        property_id: propertyId,
        data_id: dataId,
        value: value,
      },
    }));
  };

  const handleNext = () => {
    if (currentStep < activeSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // const handleBack = () => {
  //   if (currentStep > 0) {
  //     setCurrentStep(currentStep - 1);
  //   }
  // };

  const handleSubmit = async(e) => {
    setLoading(true);
    let Element = null;
    e.preventDefault();
    const dataToSend = Object.values(formData).map((item) => ({
      property_id: item.property_id,
      value: item.value,
      data_id: item.data_id
    }));

    if(dataToSend[currentStep] && dataToSend[currentStep].property_id && dataToSend[currentStep].value) {
      const element_data = JSON.parse(localStorage.getItem('elementData'));
      if (element_data) {
        try {
          if(!dataToSend[currentStep].data_id || dataToSend[currentStep].data_id === undefined) {
            Element = await InputsService.includeInputData(flowchart_id, element_data.element_id, element_data.event_id, dataToSend[currentStep].property_id, dataToSend[currentStep].value);
          } else {
            Element = await InputsService.editInputData(flowchart_id, element_data.element_id, element_data.event_id,  dataToSend[currentStep].data_id,dataToSend[currentStep].property_id, dataToSend[currentStep].value);
          }
          if (Element.status === 200) {
            setErros({});
            setAlert(true);
            if (currentStep === activeSteps.length - 1) {
              setIsSubmitted(true);
            } else {
              handleNext();
            }
            setLoading(false);

            setTimeout(() => {
              setAlert(false);
            }, 3000);
          } else {
            erros.submit = Element.message;
            setErros(erros);
            setAlert(true);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    } else{
      erros.submit = "Preenchimento obrigatório!";
      setErros(erros);
      setAlert(true);
      setLoading(false);
    } 
  };

  const backforstart  = () => {
      navigate(-1); // Volta para a rota anterior
  }

  if (isSubmitted) {
    return (
      <div className='form_finish'>
        <h1>Formulário preenchido com sucesso!</h1>
        <Button size='md' variant='primary' onClick={backforstart}>Voltar ao início</Button>
        <div>
          <CompleteCheck />
        </div>
      </div>
    );
  }

  if (!Array.isArray(activeSteps) || activeSteps.length === 0) {
    return <div>Nenhum passo disponível para o formulário.</div>;
  }

  return (
    <div className='container_formdata'>
      {loading && (
        <Loading /> 
      )}
      <form onSubmit={handleSubmit}>
        {/* Renderizando o step atual */}
        <div className='container_formdata_input'>
          <div className='formdata_title'>
            <label>{activeSteps[currentStep].name}</label>
            <p>{activeSteps[currentStep].description}</p>
          </div>
          {activeSteps.length > 0 ? (
              <div>
                {activeSteps[currentStep].data_type === 'textarea' ? (
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name={activeSteps[currentStep].name}
                    value={formData[activeSteps[currentStep].name]?.value || ''}
                    onChange={(e) => handleChange(e, activeSteps[currentStep].property_id, activeSteps[currentStep].data_id)}
                    data-property-id={activeSteps[currentStep].property_id}
                  />
                ) : (
                  <Form.Control
                    type= {Convert.convertOptionsDataTypeForm(activeSteps[currentStep].data_type)}
                    name={activeSteps[currentStep].name}
                    value={formData[activeSteps[currentStep].name]?.value || ''}
                    onChange={(e) => handleChange(e, activeSteps[currentStep].property_id, activeSteps[currentStep].data_id)}
                    data-property-id={activeSteps[currentStep].property_id}
                  />
                )}
              </div>
            ) : (
              <div>Nenhum passo disponível para o formulário.</div>
            )}
        </div>
      </form>
      <div className='container_formdata_actions'>
        {/* {currentStep > 0 && (
            <Button variant='outline-secondary' size="md" onClick={handleBack}>
              Voltar
            </Button>
          )} */}
          <Button variant='primary' size="md" type="submit" onClick={handleSubmit}>
            {currentStep < activeSteps.length - 1 ? 'Salvar e Continuar' : 'Finalizar'}
          </Button>
      </div>
      <div>
        {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Salvo com sucesso!</Alert>}
        {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
      </div>
    </div>
  );
};

export default DynamicStepForm;
