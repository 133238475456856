import React, { useState } from 'react';
import { Modal, Button, Accordion } from 'react-bootstrap';
import InputsService from '../../../../services/inputs';

const PropertiesInfo = ({ eventIds }) => {
  const [show, setShow] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setEventDetails([]);
    fetchEventDetails();
    setShow(true);
  };

  const fetchEventDetails = async () => {
        const elementData = JSON.parse(localStorage.getItem('elementData'));
        if (!elementData) return;
    
        setLoading(true);
        try {
        // Busca todos os eventos
        const response = await InputsService.getEventsFlowchart(elementData.flowchart_id);
        if (response.status === 200) {
            let allEvents = response.data.data;
    
            // Ordena os eventos pelo `event_id`
            allEvents = allEvents.sort((a, b) => a.event_id - b.event_id);
    
            // Busca detalhes para cada evento filtrado
            const detailsPromises = allEvents.map(event =>
            InputsService.getElement(elementData.flowchart_id, event.element_id, event.event_id)
            );
    
            const detailsResponses = await Promise.all(detailsPromises);
            const detailsData = detailsResponses
            .filter(res => res.status === 200) // Filtra apenas as respostas bem-sucedidas
            .flatMap(res => res.data.data); // Flatten para lidar com dados agrupados por eventos
    
            setEventDetails(detailsData);
        }
        } catch (error) {
        console.error("Erro ao buscar detalhes dos eventos:", error);
        } finally {
        setLoading(false);
        }
    };
    
    const getElementNameById = (elementId) => {
        // Recupera os dados do localStorage
        const elementData = JSON.parse(localStorage.getItem('elementsFlowData'));
        if (!elementData) {
          console.error("Nenhum dado encontrado no localStorage.");
          return null;
        }
      
        // Procura o elemento pelo element_id
        const element = elementData.find(el => el.element_id === elementId);
      
        // Retorna o nome do elemento, ou null se não encontrar
        return element ? element.name : null;
    };

    const createEventDetailsArray = (eventDetails) => {
        return eventDetails.map((event) => {
        const outputsNames = event.flow_outputs?.map((dataItem) => dataItem.name).join(' - ') || '';
        const flow_inputs = event.flow_inputs?.map((dataItem) => dataItem.production_input?.name).join(' - ') || '';
        const eventData = event.event_data?.map((dataItem) => ({
            name: dataItem.element_property?.name,
            value: dataItem.value || 'Propriedade não coletada',
        })) || [];

        return {
            name: getElementNameById(event.element_id),
            event_id: event.event_id,
            output_names: outputsNames,
            flow_inputs: flow_inputs,
            event_data: eventData,
        };
        });
    };

    const accordionDetails = createEventDetailsArray(eventDetails);

  return (
    <>
      {eventIds.length > 0 && (
        <Button variant="primary" size="sm" onClick={handleShow}>
          Rastreio
        </Button>
      )}

      <Modal show={show} onHide={handleClose} size="lg" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Rastreio de informações propriedades</Modal.Title>
        </Modal.Header>
        <Modal.Body className="container_form_new_user">
          <div className="container_descripiton">
            {loading ? (
              <p>Carregando...</p>
            ) : accordionDetails.length > 0 ? (
              <Accordion>
                {accordionDetails.map((event, index) => (
                  <Accordion.Item eventKey={index.toString()} key={event.event_id}>
                    <Accordion.Header>{event.name} - evento {event.event_id}</Accordion.Header>
                    <Accordion.Body>
                      <p><strong>Entradas:</strong> {event.flow_inputs || 'Nenhuma entrada'}</p>
                      <p><strong>Saídas:</strong> {event.output_names || 'Nenhuma saída'}</p>
                      
                      {event.event_data.length > 0 ? (
                        event.event_data.map((dataItem, dataIndex) => (
                          <p key={dataIndex}>
                            <strong>{dataItem.name}:</strong> {dataItem.value}
                          </p>
                        ))
                      ) : (
                        <p>Não foram coletadas propriedades.</p>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            ) : (
              <p>Nenhum detalhe encontrado para os eventos selecionados.</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PropertiesInfo;
